import { Component ,ViewChild} from '@angular/core';
import { CommonService } from '../services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: ['./cache.component.scss']
})
export class CacheComponent  {
  cachelist:any[]=[];
  pagenumber: number = 1;
  pageSize :number= 100;
  totalrecords: number;
  cachekey:string ="";
  isChecked:boolean;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  constructor(private service:CommonService, private ngxUiLoaderService: NgxUiLoaderService,private toastr: ToastrService) { }

  ngOnInit() {
    this.selectedRow(null,null);
  }

  clearCache(){
    this.ngxUiLoaderService.start();
    this.service.clearCache().subscribe(data=>{
      if(data['status'] =='success'){
        this.ngxUiLoaderService.stop();
        this.toastr.success(data.statusmessage, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
       });
       }
      else{
        this.ngxUiLoaderService.stop();
        this.toastr.error(data.statusmessage, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
       });
      }
     });
   }

   GetSearch(){
   var obj = {
    "pageNumber" : this.pagenumber,
    "pageSize":this.pageSize,
    "keysearch" : this.cachekey
   }
   this.ngxUiLoaderService.start();
    this.service.getCoreCache(obj).subscribe(data => {
      if(data.length>0){
        data.forEach((V,index) => {
          let obj={}
          obj['key']=V["key"];
          obj['ischecked']=false;
          this.totalrecords=V["count"];
          if (this.pagenumber > 0 && this.paginator != undefined) {
            this.paginator.length = this.totalrecords;
          }
          this.ngxUiLoaderService.stop();
          this.cachelist.push(obj);
        });
      }else{
        this.ngxUiLoaderService.stop();
        this.toastr.error("Cache data not found", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
       });
       this.cachelist=[];
      }
    }), 
    error => 
    {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Error Occured", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.cachelist =[];
    };
  }

  selectedRow(data,obj)
  {
    if(obj != null){
    obj['ischecked']=data.checked;
    let checkedList = this.cachelist.filter(V=>V.ischecked==true);
   if(checkedList.length >0){
    this.isChecked =true;
   }
   else{
    this.isChecked=false;
   }
  }
  else{
    this.isChecked=false;
  }
  }
  rePopulate(){
    let filteredData=this.cachelist.filter(V=>V.ischecked==true);
    let newdata=this.cachelist.filter(V=>V.ischecked!=true);
    let finalString='';
    filteredData.forEach(V=>{
      finalString+=V['key']+',';
    });
    this.ngxUiLoaderService.start();
    this.service.getClearCache(finalString.substring(0, finalString.length - 1)).subscribe(data => {
      if(data["status"]=='Success'){
        this.cachelist=newdata;
      this.toastr.success("Re-Populated Successfully", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
     });
      }
      else{
        this.toastr.error("Failed to re-populate", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
       });
      }
      
    }),error=>{
      this.ngxUiLoaderService.stop();
      this.toastr.error("Error Occured", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
     });
    };
    this.ngxUiLoaderService.stop();
  }
  event: any;
  getPageDetails(event: { pageIndex: number; pageSize: number }) {
    if (event.pageIndex == 0) {
      this.pagenumber = 1;
    } else {
      this.pagenumber = event.pageIndex + 1;
    }

    this.pageSize = 100;
    this.GetSearch();
  }
  searchInputCleared(event) {
    this.cachekey = "";
    this.cachelist = [];
  }

}
