import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { NewauditComponent } from '../newaudit/newaudit.component';
declare const copyToClipboard: any;

@Component({
  selector: 'app-productagent',
  templateUrl: './productagent.component.html',
  styleUrls: ['./productagent.component.scss']
})
export class ProductagentComponent implements OnInit {
  reactiveFormagent: FormGroup;
  reactiveFormprodaddeditagent: FormGroup;
  reactiveFormappname:FormGroup;
  reactiveFormdomain:FormGroup;
  reactiveFormdomainalias:FormGroup;
  reactiveFormstatus:FormGroup;
  prodagentobj:any={};

  constructor(private _fb: FormBuilder,private service:CommonService,private ngxUiLoaderService: NgxUiLoaderService,private toastr: ToastrService,private modalService: NgbModal) {
    this.reactiveFormagent = _fb.group({ agentname: '' });
    this.reactiveFormappname= _fb.group({ appname: '' });
    this.reactiveFormdomain= _fb.group({domain : '' });
    this.reactiveFormdomainalias=_fb.group({ domainalias: '' });
    this.reactiveFormstatus=_fb.group({status: '' });
    this.reactiveFormprodaddeditagent=_fb.group({prodeditaddagentname: '' });
  }
  isLoadingagent:boolean=false;
  isLoadingprodaddagent:boolean=false;
  AgentNameList: any[];
  ProductAgentList=<any>[];
  notfounddata:boolean=false;
  selectedAgentguid:string='';
  selectedAgentName:string='';
  keywordsearch = "displayname";
  keyword = "name";
  cardHeight: Number = window.innerHeight - 170;
  prodagentstatusvalue:string='';
  isprodagentupdate:boolean=false;
  title:string='';
  public productagentstatus = [
    {
      name: 'Active',
    },
    {
      name: 'Passive',
    }
  ];

  ngOnInit() {

  }

  getAgentNames(event: any,isprodagentupdate) {
    var val = event;
    if (val) {
      if(isprodagentupdate){
        this.isLoadingprodaddagent=true;
      }
      else{
        this.isLoadingagent = true;
      }


      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else {
            this.AgentNameList = data as any[];
            this.AgentNameList=this.service.transformAlphabetically(this.AgentNameList ,'displayname');
            this.AgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'} ${'('} ${obj.apikey} ${')'}`);
            if(isprodagentupdate){
              this.isLoadingprodaddagent=false;
            }
            else{
              this.isLoadingagent = false;
            }
            if (data.length == 0) {
              this.notfounddata = true;
            }
            else {
              this.notfounddata = false;
            }
          }
        },
        error: error => {
          this.isLoadingagent = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchAgentNameCleared(isprodagentupdate) {
    if(isprodagentupdate){
      this.isLoadingprodaddagent=false;
    }
    else{
      this.isLoadingagent = false;
    }
    this.AgentNameList = [];
    this.selectedAgentguid = '';
    this.notfounddata = false;
    this.selectedAgentName='';
  }
  selectedAgent(data) {
    this.selectedAgentguid = data.id;
    this.selectedAgentName = data.name;
  }

  GetProdAgentDataSearch(){
    let validate=true;
    if(this.reactiveFormagent.get("agentname").value &&  !this.selectedAgentguid ){
      validate=false;
      this.toastr.warning("No results found!!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    if(this.reactiveFormstatus.get("status").value && !this.prodagentstatusvalue){
      validate=false;
      this.toastr.warning("No results found!!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    if(validate){
      this.ngxUiLoaderService.start();
    var agentObj = {
      agentid: this.selectedAgentguid ? this.selectedAgentguid : "",
      appname:  this.reactiveFormappname.get("appname").value,
      domain:  this.reactiveFormdomain.get("domain").value,
      domainalias:  this.reactiveFormdomainalias.get("domainalias").value,
      status:this.prodagentstatusvalue?this.prodagentstatusvalue.toLowerCase():''
    };
    var apiname = "api/ProductAgent/GetProductAgentSearch";
    this.service.postapi(apiname, agentObj).then(data => {
      if (data) {
        if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
        else{
          this.ngxUiLoaderService.stop();
          this.ProductAgentList = data as any[];
        }
      }
    })
    }

  }
  selectprodagentstatus(item){
    this.prodagentstatusvalue=item.name;
  }
  clearedagentstatus(item) {
    this.prodagentstatusvalue = '';
  }
  reset(){
    this.reactiveFormagent.reset();
    this.reactiveFormappname.reset();
    this.reactiveFormdomain.reset();
    this.reactiveFormdomainalias.reset();
    this.reactiveFormstatus.reset();
    this.prodagentstatusvalue='';
    this.ProductAgentList=[];
    this.selectedAgentName ='';
    this.selectedAgentguid='';
    this.AgentNameList=[];
    this.reactiveFormstatus.setValue({ "status": '' });
  }
  clear(){
    this.isprodagentupdate=false;
    this.title="";
    this.selectedAgentguid="";
    this.selectedAgentName="";
    this.reactiveFormprodaddeditagent.reset();
    this.isLoadingagent=false;
    this.prodagentobj={};
  }
  openaddoreditprodagent(obj,modal){
      if(obj){
        this.isprodagentupdate=true;
        this.title="Update Product Agent"
        this.prodagentobj=JSON.parse(JSON.stringify(obj));
        if( this.prodagentobj.status){
          if( this.prodagentobj.status=="active"){
            this.prodagentobj.status=true;
          }
          else{
            this.prodagentobj.status=false;
          }
        }
        if( this.prodagentobj.monitoring){
          if( this.prodagentobj.monitoring=="enabled"){
            this.prodagentobj.monitoring=true;
          }
          else{
            this.prodagentobj.monitoring=false;
          }
        }
        this.selectedAgentguid=this.prodagentobj.agentid;

        this.reactiveFormprodaddeditagent.patchValue({
          prodeditaddagentname: `${this.prodagentobj.agentdisplayname} ${'(' + this.selectedAgentguid + ')'}`
        })
        this.modalService.open(modal, { windowClass: "prodagentmodaladd", backdrop: 'static', keyboard: false }).result.then((result) => { });
      }
      else{
        this.prodagentobj={
          domain:'',
          authurl:'',
          appname:'',
          appversion:'',
          tokenurl:'',
          domainalias:'',
          guidinfourl:'',
          deployment:'',
          status:true,
          agentid:'',
          monitoring:true
        }
        this.title="Add Product Agent";
        this.modalService.open(modal, { windowClass: "prodagentmodaladd", backdrop: 'static', keyboard: false }).result.then((result) => { });
      }
  }
  closepopup(c){
    this.clear();
    c('close modal');
  }
  validate():boolean{
    let isvalid=true;
    if(!this.selectedAgentguid){
      isvalid=false;
    }
    else if(!this.prodagentobj.domain){
      isvalid=false;
    }
    else if(!this.prodagentobj.domainalias){
      isvalid=false;
    }
    else if(!this.prodagentobj.appname){
      isvalid=false;
    }
    else if(!this.prodagentobj.appversion){
      isvalid=false;
    }
    else if(!this.prodagentobj.authurl){
      isvalid=false;
    }
    return isvalid;
  }
  saveproductagent(c){
    if(this.validate()){
      this.ngxUiLoaderService.start();
      let obj=JSON.parse(JSON.stringify(this.prodagentobj));
      obj.status=obj.status?"active":"passive";
      obj.agentid=this.selectedAgentguid;
      obj.monitoring=obj.monitoring?"enabled":"disabled";
      obj["modifierid"]= JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
      this.service.postapi("api/ProductAgent/SaveProductAgent", obj).then(response => {
        if (response) {
          if ((response['status'] && response['statusText']) || response['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else {
            if(this.isprodagentupdate){

              this.GetProdAgentDataSearch()
              this.ngxUiLoaderService.stop();
              this.toastr.success("Product Agent Updated Successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.closepopup(c);
            }
            else{
              this.reset();
              this.GetProdAgentDataSearch()
              this.ngxUiLoaderService.stop();
              this.toastr.success("Product Agent Created Successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.closepopup(c);
            }
          }
        }
      })
    }
    else{
      this.toastr.warning("Please enter the required fields", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
  }
  openAuditPopUp(uniqueid: any, agentguid: any) {

    if (uniqueid != '' && agentguid != '') {
      const auditDataToModal = {
        id: uniqueid,
        title:agentguid ,
        context: "Product Agent",
        type: "ID",
        guid: agentguid,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }
  }
  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

}

