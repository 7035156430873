import { createComponent, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

import { AppConfigService } from '../configurations/app-config.service';
import { FileToUpload } from '../interfacemapping/interfacemapping.component';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { LoginService } from '../login/login.service';
import { url } from 'inspector';
import { InterfaceComponent } from '../CIIS/interface/interface.component';
import { RequestFormComponent } from '../CIIS/request-form/request-form.component';
import { ManageMenusComponent } from '../CIIS/manage-menus/manage-menus.component';
import { TryitoutComponent } from '../tryitout/tryitout.component';
import { CreateComponent } from '../create/create.component';
import { AgentComponent } from '../agents/agent/agent.component';
import { HubmessagesComponent } from '../hubmessages/hubmessages.component';
import { BadmessageComponent } from '../badmessage/badmessage.component';
import { ErrormessagelistComponent } from '../errormessage/errormessagelist.component';
import { BulkmessagereprocessComponent } from '../bulkmessagereprocess/bulkmessagereprocess.component';
import { W700jobslistComponent } from '../w700jobslist/w700jobslist.component';
import { CompendiumComponent } from '../compendium/compendium.component';
import { JobComponent } from '../job/job.component';
import { AgentlistComponent } from '../agents/agentlist/agentlist.component';
import { ProductagentComponent } from '../productagent/productagent.component';
import { Case360Component } from '../case360/case360.component';
import { IntegrationmappingComponent } from '../integrationmapping/integrationmapping.component';
import { LogsComponent } from '../logs/logs.component';
import { MetricpipelinelistComponent } from '../metricpipelinelist/metricpipelinelist.component';
import { MetricshighchartComponent } from '../metricshighchart/metricshighchart.component';
import { NonvitalcoreComponent } from '../nonvitalcore/nonvitalcore.component';
import { PipeLineDataExtractComponent } from '../pipelinedataextract/pipelinedataextract.component';
import { TemplateComponent } from '../template/template.component';
import { TracelogComponent } from '../tracelog/tracelog.component';
import { UserroleComponent } from '../userrole/userrole.component';
import { VendorlistComponent } from '../vendor/vendorlist/vendorlist.component';
import { V } from '@angular/cdk/keycodes';
import { CacheComponent } from '../cache/cache.component';
import { DocumentsComponent } from '../documents/documents.component';
import { ContactComponent } from '../vendor/contact/contact.component';
import { ContactmoduleComponent } from '../contactmodule/contactmodule.component';


@Injectable({
  providedIn: 'root'
})

export class CommonService {
  config: NgxUiLoaderConfig;
  isprocessEditedMappingData = "";
  isprocessEditedtemplatedId = 0;
  lasturl='';
  CaseDataDetails={}
  protected apiURL: string = AppConfigService.settings.WebApiURL;
  protected UserLogin: string = AppConfigService.settings.UserLogin;
  islock: boolean = false;
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  private configdatarefresh = new BehaviorSubject(false);
  dataRefreshConfig = this.configdatarefresh.asObservable();
  private viewFilecase = new BehaviorSubject(false);
  viewfileview = this.viewFilecase.asObservable();

  private vendoreCreate = new BehaviorSubject(false);
  vendorUpdate = this.vendoreCreate.asObservable();

  private updatessostatus = new BehaviorSubject(false);
  servicessostatus = this.updatessostatus.asObservable();

  private updatCaseXml = new BehaviorSubject('');
  UpdatedXmlData = this.updatCaseXml.asObservable();

  private messageSourceInterface = new Subject();
  currentMessageinterface = this.messageSourceInterface.asObservable();
  private configJson = new Subject();
  ConfigJsonFlag = this.configJson.asObservable();
  configDetails: any;
  usersessiontoken: any = "2";
  invalidrefreshtoken: boolean = false;
  messagesData = "";
  openedModalConfig =""
  isopenAlready = false
  header = { withCredentials: true }
  headers = { 'Content-Type': 'application/json charset = utf-8', withCredentials: true };
  ComponentsList=[
    {
      "DisplayName":"interface",
      "componentname":InterfaceComponent
    },
    {
      "DisplayName":"request form",
      "componentname":RequestFormComponent
    },
    {
      "DisplayName":"access control",
      "componentname":ManageMenusComponent
    },
    {
      "DisplayName":"try it out",
      "componentname":TryitoutComponent
    },
    {
      "DisplayName":"create",
      "componentname":CreateComponent
    },
    {
      "DisplayName":"agent",
      "componentname":AgentComponent
    },
    {
      "DisplayName":"messages",
      "componentname":HubmessagesComponent
    },
    {
      "DisplayName":"bad messages",
      "componentname":BadmessageComponent
    },
    {
      "DisplayName":"error messages",
      "componentname":ErrormessagelistComponent
    },
    {
      "DisplayName":"bulk-reprocess",
      "componentname":BulkmessagereprocessComponent
    },
    {
      "DisplayName":"w700-jobs",
      "componentname":W700jobslistComponent
    },
    {
      "DisplayName":"compendium",
      "componentname":CompendiumComponent
    },
    {
      "DisplayName":"jobs",
      "componentname":JobComponent
    },
    {
      "DisplayName":"processor",
      "componentname":AgentlistComponent
    },
    {
      "DisplayName":"product agent",
      "componentname":ProductagentComponent
    },
    {
      "DisplayName":"vendor",
      "componentname":VendorlistComponent
    },
    {
      "DisplayName":"userrole",
      "componentname":UserroleComponent
    },
    {
      "DisplayName":"case 360 gold",
      "componentname":Case360Component
    },
    {
      "DisplayName":"logs",
      "componentname":LogsComponent
    },
    {
      "DisplayName":"metrics list",
      "componentname":MetricpipelinelistComponent
    },
    {
      "DisplayName":"metrics chart",
      "componentname":MetricshighchartComponent
    },
    {
      "DisplayName":"non-core",
      "componentname":NonvitalcoreComponent
    },
    {
      "DisplayName":"trace-logs",
      "componentname":TracelogComponent
    },
    {
      "DisplayName":"integrationmapping",
      "componentname":IntegrationmappingComponent
    },
    {
      "DisplayName":"pipeline dataextract",
      "componentname":PipeLineDataExtractComponent
    },
    {
      "DisplayName":"template",
      "componentname":TemplateComponent
    },
    {
      "DisplayName":"cache",
      "componentname":CacheComponent
    },
    {
      "DisplayName":"documents",
      "componentname":DocumentsComponent
    },
    {
      "DisplayName":"contacts",
      "componentname":ContactmoduleComponent
    }

  ]
  private pipelineNameSource = new Subject<string | null>();
  pipelineName$ = this.pipelineNameSource.asObservable();

  setPipelineName(pipelineName: string) {
    this.pipelineNameSource.next(pipelineName);
  }

  private messageInterface = new Subject<string | null>();
  message$ = this.messageInterface.asObservable();
  setMessageObj(data: any) {
    this.messageInterface.next(data);
  }

  private vendorlistcomp = new Subject<string | null>();
  vendorinterface$ = this.vendorlistcomp.asObservable();
  setvenintObj(data: any) {
    this.vendorlistcomp.next(data);
  }

  stopEmitting(displayname:string) {
    switch(displayname.toLowerCase()){
      case 'create':
        this.pipelineNameSource.next(null);
        break; 
      case 'messages':
        this.messageInterface.next(null);
        break; 
      case 'interface':
        this.vendorlistcomp.next(null);
        break; 
    }
  }


  constructor(private httpService: HttpClient,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
  }

  getapiurl(urlterm) {

    var listOfNames = this.httpService.get(this.apiURL + urlterm, this.header)
      .pipe(
        debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map(
          (data: any) => {
            return (
              data as any[]
            );
          }
        ));

    return listOfNames;
  }

  async postapi(urlterm: any, createSearch: any) {

    return await this.httpService.post<any>(this.apiURL + urlterm, createSearch, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }

  async searchpostapi(urlterm, createSearch: any) {
    const headers = {
      'Content-Type': 'application/json charset = utf-8'
    };


    return await this.httpService.post<any>(this.apiURL + urlterm, JSON.stringify(createSearch), { headers, withCredentials: true })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }

  response: any[];
   savemappings(mappingentity, apiname) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return  this.httpService.post<any>(this.apiURL + apiname, mappingentity, { headers, withCredentials: true, responseType: 'text' as 'json' })
      // .pipe().toPromise()
      // .then(result => result)
      // .catch(err => err);

  }


  async deletemapping(apinamevalue) {

    return await this.httpService.delete(this.apiURL + apinamevalue, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }


  async filedownloadblob(urlterm, header: any) {
    const headers = header;
    return await this.httpService.get<any>(this.apiURL +urlterm, { headers, withCredentials: true, responseType: 'blob' as 'json' });
  }

  async getapi(urlterm) {

    return await this.httpService.get<any>(this.apiURL + urlterm, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }

 dataValidationapi(parameter:any){
   return this.httpService.post<any>( `${this.apiURL}api/DataValidation/ValidateCaseData`, parameter,{withCredentials: true})
}
  async reprocessStageapi(urlterm, createSearch: any) {
    return await this.httpService.post<any>(this.apiURL + urlterm, createSearch, { withCredentials: true, responseType: 'text' as 'json' })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }

  async dxpostapi(urlterm, entity) {
    return await this.httpService.post<any>(this.apiURL + urlterm, entity, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }
  dxgetapi(urlterm, deploymentkey) {
    const headers = { 'Conn': deploymentkey };
    return this.httpService.get(this.apiURL +urlterm, { headers ,withCredentials: true,})
      .pipe(map(result => result));

  }
  getapiurlcontenttext(urlterm) {
    return this.httpService.get<any>(this.apiURL + urlterm, { withCredentials: true, responseType: 'json' });
  }
  getapiurlcontent(urlterm) {

    return this.httpService.get<any>(this.apiURL + urlterm, this.header);
  }

  // for entity api
  async entitygetapi(createSearch: any) {

    return await this.httpService.post<any>(this.apiURL, createSearch, this.headers)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }


  //audit save
  async auditsave(actiontype: string, oldvalue: any, newvalue: any, context: any, contextid: any,description:any) {
    var apiname = 'api/Audit/SaveAudit';
    var auditobj = {
      "UserName": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "ActionType": actiontype,
      "OldValue": oldvalue,
      "NewValue": newvalue,
      "Context": context,
      "ContextId": contextid,
      "Description":description
    }
    return await this.httpService.post<any>(this.apiURL + apiname, auditobj, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }


  async uploadExcel(urlterm, file: any) {
    // const headers = new HttpHeaders().append('Content-Type', 'Plain/Text');
    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    const headers = {
      'Content-Type': 'Plain/Text'
    };
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpService.post<any>(this.apiURL + urlterm, formData, { headers, withCredentials: true })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }

  checksession() {
    // var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    // if (sessionObject != null) {
    //   return true;
    // }
    // else {
    //   sessionStorage.removeItem('sessionObjectlogin');
    //   return false;
    // }
    return true;
  }
  checkserversession() {
    return this.loginService.getUserInfo()

  }


  async postapiwithurl(fullurlterm: any, inputentity: any, apiheaders: any) {

    apiheaders["Content-Type"] = "application/json";
    apiheaders["Access-Control-Allow-Origin"] = "*";
    apiheaders["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    apiheaders["Access-Control-Allow-Methods"] = "GET,POST,OPTIONS,DELETE,PUT";
    return await this.httpService.post<any>(fullurlterm, JSON.stringify(inputentity), { headers: apiheaders })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }

  uploadFile(urlterm, theFile: FileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), withCredentials: true
    };
    return this.httpService.post<FileToUpload>(this.apiURL + urlterm, theFile, httpOptions);
  }
  changeMessage(ischeck: boolean) {
    this.messageSource.next(ischeck)
  }
  changeConfigmodal(ischeck: boolean) {
    this.configdatarefresh.next(ischeck)
  }
  closeviewFile(ischeck: boolean) {
    this.viewFilecase.next(ischeck)
  }
  changeModal(isSize: object) {
    this.configJson.next(isSize)
  }
  changeJson(isSize: object) {
    this.configJson.next(isSize)
  }

  vendorStatus(update: boolean) {

    this.vendoreCreate.next(update)
  }

  XmlModification(xml) {
    return this.httpService.post<any>(`${this.apiURL}api/GetMappings/ConvertXML`, xml, this.headers)
  }

  Hubmessage_IOTData(uniqueid,payloadreference, Archive) {
    let createdby=JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
    return this.httpService.get(`${this.apiURL}api/HubMessage/GetInputTransformOutputData?messageRefId=${uniqueid}&fileid=${payloadreference}&archivedbcheck=${Archive}&createdby=${createdby}`, this.header)
  }
  downloadfile(urlterm): Observable<HttpResponse<Blob>> {

    return this.httpService.get(`${this.apiURL}${urlterm}`, { withCredentials: true, responseType: 'blob', observe: 'response' })
  }

  getmetricapiurl(urlterm) {
    var listOfNames = this.httpService.get(this.apiURL + urlterm, this.header)
      .pipe(
        debounceTime(500),
        map(
          (data: any) => {
            return (
              data as any[]
            );
          }
        ));

    return listOfNames;
  }
  transformAlphabetically(array: any[], field: string): any[] {
    return array.sort((a, b) => a[field].toLowerCase() !== b[field].toLowerCase() ? a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1 : 0);
  }

  clearCache() {
    var id=JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    return this.httpService.post<any>(`${this.apiURL}api/CoreCache/DeleteCache?userid=${id}`,'', this.header)
  }

  getJobSearch(job) {
    return this.httpService.post<any>(`${this.apiURL}api/Jobs/GetJobSearch`, job, this.headers);
  }

  createJob(job) {
    return this.httpService.post<any>(`${this.apiURL}api/Jobs/SaveJob`, job, this.headers);
  }

  getJobLogs(jobid: string, currentPage: number, itemsPerPage: number) {
    return this.httpService.get<any>(
      `${this.apiURL}api/Jobs/GetJobLogs?jobid=${jobid}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`,
      this.headers
    );
  } 

  IsJsonString(str) {
    let isvalid = false;
    try {
      JSON.parse(str);
      isvalid = true;
    } catch (e) {
      isvalid = false;
    }
    return isvalid
  }



  // InterfaceCIIS service will start from here

  getInterfaces() {
    return this.httpService.get<any>(`${this.apiURL}api/GetInterfaceList/GetInterfacescon `)
  }
  getInterfaceFilters(interfacename: any, engine: any, vendor: any) {
    return this.httpService.get<any>(`${this.apiURL}api/VAInterfaces/GetVAInterfacesbysearch?interfacename=${interfacename}&Engine=${engine}&Vendor=${vendor}`)
  }
  getfilterdata(column: any, userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceFieldFilter/GetInterfacesfieldsearch?column=${column}&userinput=${userinput}`)
  }
  getSearchFilterData(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfacebyInput/GetVAInterfaces`, body);
  }

  changeMessageinterface(message: string) {
    this.messageSourceInterface.next(message)
  }
  getDescription() {
    return this.httpService.get<any>(`${this.apiURL}api/ConnectivityTypes/vaConnectivityTypes`)
  }
  HubMapping(Interfacegroupid: any, Processorid: any) {
    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    return this.httpService.get<any>(`${this.apiURL}api/InterfacePipelineConfig/pipelineconfigdata?token=${sessionObject.userdata['usertokenString']}&interfacegrpid=${Interfacegroupid}&processorid=${Processorid}`)
  }
  getAudit(interfaceidentifier: any, engine: any, deployment: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceAudit/getAuditsData?InterfaceName=${interfaceidentifier}&InterfaceType=${engine}&Connection=${deployment}`)
  }

  getHubAudit(id: any) {
    return this.httpService.get<any>(`${this.apiURL}api/Audit/GetAuditDetails?id=${id}&context=Pipeline`, this.header)
  }
  getHubView(Interfacegroupid: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceHub/getHubViewData?Interfacegroupid=${Interfacegroupid}`)
  }
  getFolders(interfaceid: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceFiles/getFilesData?InterfaceId=${interfaceid}`, this.headers)
  }
  getTransactions(interfacename: any, Engine: any, Deployment: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceTransactions/getTransactionData?interfacename=${interfacename}&InterfaceType=${Engine}&Connection=${Deployment}`)
  }
  getHandlesData(interfacename: any) {
    return this.httpService.get<any>(`${this.apiURL}api/IntergrationMapping/getIntegrationData/${interfacename}`)
  }
  getRulesData(interfacename: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceRuleData/getRuleData/${interfacename}`)
  }
  viewFile(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceViewFile/FileData`, body)
  }
  PostFormData(obj: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/InterfaceFormAdd`, obj)
  }
  insertInterfacefromFile(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/InsertInterfacefromFile`, body)
  }
  EntityInsertion(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/Insertentity`, body);
  }
  updateformdata(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/IntFormUpdate`, body);
  }
  createpipeline(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Routes/SavePipeline`, body, this.header)
  }
  insertNewPipeline(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InsertPipeline/InsertNewPipeline`, body);
  }
  getformdata(value: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceForm/InterfaceFormView?userinput=${value}`);
  }
  finalizeformdata(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/InterfaceFormSubmit`, body);
  }
  getproccessorapidata(value: any) {
    return this.httpService.get<any>(`${this.apiURL}api/Agents/GetByDisplayName?name=${value}&agentid=0&type=Processor&filterflag=true`, this.header)
  }
  getagents(body) {

    return this.httpService.post<any>(`${this.apiURL}api/Agents/GetSearchDetails`, body, this.header)
  }
  getproccessordata(obj: any) {
    return this.httpService.get<any>(`${this.apiURL}api/Interfacesenderandprocessoragent/Processorandsenderagents?contenttype=${obj.contenttype}&msgtype=${obj.msgtype}&direction=${obj.direction}`)
  }
  getEntityfilterdata(userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/SearchAccounts/GetAccountNamesListAsync?lSearch=${userinput}`, this.header)
  }
  getMsgtypedata() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetMessageType?lookuptype=messagetype`, this.header)
  }
  getrole(roleid: any, rolename: any) {
    if (roleid == null && rolename == null) {
      return this.httpService.get<any>(`${this.apiURL}api/UserRoleConfig/GetRoles`, this.header);
    }
    // return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetRoles?RoleId=0`, this.header);
  }
  getRoleandMenu(rid: number) {
    return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetRoleMenus?RoleId=${rid}`, this.header);
  }
  postRoleMenu(body) {
    return this.httpService.post<any>(`${this.apiURL}api/RoleMenu/SaveRoleMenu`, body, this.header)
  }
  getMenus(rid: number) {
    return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetMenus`, this.header);
  }
  GetActionbyId(mid: number) {
    return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetMenuActionsbyId?MenuId=${mid}`, this.header);
  }
  ManageActions(body) {
    return this.httpService.post<any>(`${this.apiURL}api/RoleMenu/SaveAction`, body, this.header)
  }

  AddRoles(body) {
    return this.httpService.post<any>(`${this.apiURL}api/RoleMenu/SaveRole`, body, this.header)
  }
  eventReprocesses(body, deployement) {
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/updateEventstatus`, body, { headers: header, withCredentials: true })
  }
  getMirthFilters(column: any, userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/GetMirthLog/GetMirthFilters?column=${column}&userinput=${userinput}&ConnNew=THX`);
  }

  getEventsList(body, deployement) {
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };

    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/searcheventdata`, body, { headers: header, withCredentials: true })
  }
  getEventType() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Eventtype`, this.header)
  }
  addIntegrationMapping(body) {
    return this.httpService.post<any>(`${this.apiURL}api/IntergrationMapping/addIntergration`, body)
  }
  // updateIntegrationMapping(body) {
  //   return this.httpService.post<any>(`${this.apiURL}api/IntergrationMapping/updateIntergration`, body)
  // }
  // hubmessageResolve(id: any, modifiedby: any) {

  //   return this.httpService.put<any>(`${this.apiURL}api/HubMessage/HubMessageStatusUpdate?id=${id}&modifiedby=${modifiedby}&stageid=-1`, '', { withCredentials: true })
  // }
  reprocessapi(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceHL7Update/UpdateHL7Integration`, body)
  }
  getMirthSearch(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/GetMirthLog/GetMirthLog`, body);
  }
  requestUnlock(UniqueID: any, userid: any, isUnlockRequest: any, isApproveUnlock: any, description: any,routeGUID:any,deployement) {
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };
    let obj = {
      "routeUniqueID": UniqueID,
      "user": userid,
      "isUnlockRequest": isUnlockRequest,
      "isApproveUnlock": isApproveUnlock,
      "description": description,
      "routeGUID":routeGUID
    }
    return this.httpService.post<any>(`${this.apiURL}api/Routes/InterfaceLock`, obj, { headers: header, withCredentials: true })
  }
  getContactNames(column: any, userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/ContactModule/GetContactNames?column=${column}&userinput=${userinput}`, this.header)
  }
  getContacts(userinput) {
    return this.httpService.post<any>(`${this.apiURL}api/ContactModule/GetContacts`, userinput, this.header)
  }
  updateContactModule(body) {
    return this.httpService.post<any>(`${this.apiURL}api/ContactModule/UpdateContact`, body, this.header)
  }
  addNewContact(body) {
    return this.httpService.post<any>(`${this.apiURL}api/ContactModule/AddContact`, body, this.header)
  }

  createContact(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceVendor/VendorContacts?userinput=create`, body);
  }

  updateContact(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceVendor/VendorContacts?userinput=update`, body);
  }

  viewContact(item: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceVendor/VendorContactsView?Vendorid=${item}`)
  }

  contactStatusUpdate() {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceVendor/VendorContactsstatusupdate?Contactid=1&Status=0`)
  }

  Deletecontact(userinput: any) {
    return this.httpService.delete<any>(`${this.apiURL}api/ContactModule/DeleteContact?userinput=${userinput}`)
  }

  hl7logsreprocess(body, deployement) {
    // var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXApi/UpdateIntegrationLogdata`, body, { headers: header, withCredentials: true })
  }
  logreprocessStatus() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Hl7reprocesslogstatus`, this.header)
  }
  getHl7LogsList(body, deployment) {
    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployment
    };
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXApi/GetHl7IntegrationLogdata`, body, { headers: header, withCredentials: true })
  }
  searchlogstatus() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Hl7logstatus`, this.header)
  }

  searchEventStatus() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Eventstatus`, this.header)
  }
  getEventList(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/GetCaseData `, body, this.header);
  }
  async searchduplgettapi(urlterm) {
    const headers = {
      'Content-Type': 'application/json charset = utf-8'
    };


    return await this.httpService.get<any>(this.apiURL + urlterm, { headers, withCredentials: true })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }



  // this.vbjobapiURL
  getPatienthistory(body) {
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/CheckFTPGetFiles`, body, this.header);
  }
  submitPatienthistory(body) {
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/SaveProjectInfo`, body, this.header);
  }

  submitImportPatient(body){
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/SaveHeaderTemplateInfo`,body,this.header);
  }

  Patienthistorysummary(user: string) {
    return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/GetProjectInfo?user=${user}`, this.header);
  }
  previewfile(body) {
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/GetFilePreview`, body, this.header);
  }

  case360(searchText) {
    return this.httpService.get<any>(`${this.apiURL}api/Case360/GetEventLogDetails?lSearch=${searchText}`, this.header);
  }

  case360info(searchText) {
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseInfoByAccession`, searchText,this.header);
  }
  CaseEventAndLogReprocess(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/ReprocessEventsorhl7logs`, body, this.header);
  }

  viewFileCase360(logid, routeguid) {
    return this.httpService.get<any>(`${this.apiURL}api/Case360/GetEventLogFileData?logid=${logid}&routeid=${routeguid}`, this.header);
  }
  CaseEventAndLogRegenerate(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/CaseXmlRegeneration`, body, this.header);
  }

  exportpatienthistory(body) {
    return this.httpService.post<any>(`${this.apiURL}api/CaseHistoryExport/SaveProjectInfo`, body, this.header);
  }
  getexportpatienthistory(user: string) {
    return this.httpService.get<any>(`${this.apiURL}api/CaseHistoryExport/GetProjectInfo?user=${user}`, this.header);
  }
  getlookupInfo(param) {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=${param}`, this.header)
  }
  getDeployments(param) {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookUpId?lookuptype=${param}`, this.header)
  }

  saveInterfaceForm(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/Save`, body,this.header);
  }

  submitInterfaceForm(body){
    return this.httpService.post<any>(`${this.apiURL}api/Projects/Submit`,body,this.header);
  }

  InterfaceFormList(body){
    return this.httpService.post<any>(`${this.apiURL}api/Projects/GetList`,body,this.header);
  }

  ProjectFormEdit(projid,projtype){
    return this.httpService.get<any>(`${this.apiURL}api/Projects/GetProjDetailsById?Projectid=${projid}&type=${projtype}`, this.header);
  }

  getIntContType(looktype) {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=${looktype}`, this.header)
  }

  getInterfaceDetails360(body){
    return this.httpService.post<any>(`${this.apiURL}api/case360/GetInterfaceDetailsByCaseId`,body,this.header)
  }
  getInterfaceDetailsRule(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetRuleAndCaseInfo`,body, this.header)
  }
  getInterfaceDetailsMessages360(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetHubMessages`,body,this.header)
  }
  getInterfaceDetailsMessages360Stage(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetHubMessageLogs`,body,this.header)
  }
  getCaseAuditDetails360(body){
    return this.httpService.post<any>(`${this.apiURL}api/case360/GetCaseAuditDetails`,body,this.header)
  }
  // getTransactionDetails(body){
  //   return this.httpService.post<any>(`${this.apiURL}api/case360/GetTransactionDetails`,body,this.header)
  // }
  getProjList(projid){
    return this.httpService.get<any>(`${this.apiURL}api/Projects/GetProjectNames?searchStr=${projid}`,this.header)
  }
  pdf64(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/CreatePdf`,body,this.header)
  }
  getprojects(obj) {
    return this.httpService.get<any>(`${this.apiURL}api/Projects/GetProjectNames?vendor=${obj.vendorId}&labGuid=${obj.labGuid}`,this.header)
  }
  saveprojftpdetails(body)
  {
    return this.httpService.post<any>(`${this.apiURL}api/Pipeline/SavePipelineFtpDetails`,body,this.header)
  }
  excel64(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/CreateExcel`,body,this.header)
  }
  getcompendium(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Compendium/GetCompendiumResult`,body,this.header)
  }
  mailapi(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/sendmail`,body,this.header)
  }

  getUpdateFTPInternal() {
    return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/ReVerifyFTPFiles`,this.header)
  }

  getProjInterfaces(body){
    return this.httpService.post<any>(`${this.apiURL}api/Routes/GetSearchDetails`,body, this.header)
  }
  refreshpatientImport(PID){
    return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/GetTransactionBasedDetails?PID=${PID}`,this.header)
  }

  viewFilesCase_hanlder(body){
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/GetStorageFileId`,body,this.header)
  }


  changeImportProjectStatus(projectid,status){
    let obj={
      PID:projectid.startsWith("0")? projectid.substring(1):projectid,
      Status:status
    }
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/ChangeProjectStatus`,obj,this.header)
  }

  viewCases360DXReport(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetFileContent360`,body,this.header)
  }

  getDaignostics(routeGuid:string){
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceStatistics/GetStatistics?RouteGuid=${routeGuid}`,this.header)
  }

  GetPipelineList(body){
    return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GetPipelineList`,body,this.header)
  }
  GetPipeleDetailsByUniqueID(uniqueID){
    return this.httpService.get<any>(`${this.apiURL}api/CIISV2/GetPipeleDetailsByUniqueID?uniqueID=${uniqueID}`,this.header)
  }
  GetDatewiseFailureReasonList(body){
    return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GetMessageFailureCounts`,body,this.header)
  }
  ciisreportexport(body){
    return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GenerateExcel`,body,this.header)
  }
  GenerateMappingPDF(input:any){

    return this.httpService.post<any>(`${this.apiURL}api/MappingPDF/Generate`,input,this.header)
  }

  GenerateMappingPDFLive(input:any){

    return this.httpService.post<any>(`${this.apiURL}api/MappingPDF/GetFileInfo`,input)
  }
  GetFileContentByFileID(input:any){

    return this.httpService.post<any>(`${this.apiURL}api/FileServices/GetFileContentByFileID`,input,this.header)
  }

  GetStatistics(routeid){
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceStatistics/GetStatistics?RouteGuid=${routeid}`,this.header)
  }

  updatessoStatus(update: boolean) {
    this.updatessostatus.next(update)
  }
  createTemplatelhs(body){
    return this.httpService.post<any>(`${this.apiURL}api/GetMappings/createTemplate`, body, this.header)
  }
  CreateDeviceConnectivity(body){
    return this.httpService.post<any>(`${this.apiURL}api/VBJobs/GetInstrumentCodes`,body, this.header)
  }
  GetJobSchema(name){
    return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetJobSchema?deviceName=${name}`,this.header)
  }
  CreateDeviceIntegrationJob(body){
    return this.httpService.post<any>(`${this.apiURL}api/VBJobs/CreateDeviceIntegrationJob`, body, this.header)
  }

  caseAuditDetails(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseAuditData`,body,this.header)
  }
  GetJobDetails(dname,rid){
    return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetJobDetails?agentid=${dname}&routeid=${rid}`,this.header)
  }

  //Data Validation API Calls
  sendToTopic(requestBody: any) {
    return this.httpService.post<any>(`${this.apiURL}api/DataValidation/SendToDataValidationTopic`, requestBody,this.header);
  }

  GetInterfaceValidationHistory()
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/GetValidationMasterDetails`,this.header);
  }
  InsertInterfaceValidationHistory(requestBody:any)
  {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceValidation/InsertValidationMaster`, requestBody,this.header);
  }

 GetDatabyMessageIdInfo(piplineguid,fromdate,todate)
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/GetValidatedMessagesList?pipelineguid=${piplineguid}&fromdate=${fromdate}&todate=${todate}`,this.header);
  }
  GetMessageDataByMessageId(messageid)
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/GetMessageValidationDataByMessageID?messageid=${messageid}`,this.header);
  }

  UpdateValidationMaster(requestBody:any)
  {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceValidation/UpdateValidationMaster`, requestBody,this.header);
  }

  DeleteInterfaceValidatedMessages(PipelineGUID, fromDate, toDate)
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/DeleteValidatedMessages?pipelineguid=${PipelineGUID}&fromDate=${fromDate}&toDate=${toDate}`,this.header);
  }
  GetMessageDetailsByID(messageid){
    return this.httpService.get<any>(`${this.apiURL}api/DataValidation/GetMessageDetailsByID?messageid=${messageid}`,this.header)
  }
  Getvbagentsdetails(acctguid){
    return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetVBAgentsDeatilsByAccnGUID?AccountGUID=${acctguid}`,this.header)
  }

  getCaseXMLHistory(caseguid){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseXML`,caseguid,this.header)
  }

  updateCaseXMLToComponent(data:string){
    this.updatCaseXml.next(data)
  }

  SaveRouteNotes(body:any){
    return this.httpService.post<any>(`${this.apiURL}api/Routes/SaveRouteNote`,body,this.header)
  }
  GetInterFaces(vendorid) {
    return this.httpService.get<any>(`${this.apiURL}api/Vendor/GetInterfaces?vendorid=${vendorid}`, this.header)
  }
  getRegenarateXML(body:any){
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/getCaseInfoData`,body,this.header)
  }
  SaveRegenarateXML(body:any){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/RegenerateXML`,body,this.header)
  }
  GetAccountNamesListAsync(body:any) {
    return this.httpService.post<any>(`${this.apiURL}api/SearchAccounts/GetAccountNamesListAsync`,body, this.header)
  }

  getApprovalNotes(projectid:string){
   return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/GetNoteDeatils?ProjectId=${projectid}`, this.header);
  }

   SaveApprovalnotes(urlterm, approvalObj) {
         return this.httpService.post<any>(this.apiURL + urlterm, approvalObj, this.header)
    }

    reprocessBymsgID(input:any){
      return this.httpService.post<any>(`${this.apiURL}api/HubMessage/GetMessageDetailById`,input)
    }
    getPdf( pdfname:string) {
      return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/getPDfFile?pdfName=${pdfname}`, this.header);
 }
 GetSuccessMessageList(body){
  return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GetSuccessMessageList`,body,this.header)
}
getvbdiagnostic( value:string){
  return this.httpService.get<any>(`${this.apiURL}api/VBJobs/getvbdiagnostic?input=${value}`,this.header);
}

getErrorFileInfo(body) {
  return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/getErrorFiles`, body, this.header);
}
getFileContentByMessageid(body) {
  return this.httpService.post<any>(`${this.apiURL}api/FileContectByMessageID/GetFileContectByMessageID`, body, this.header);
}
getCoreCache(body){
  return this.httpService.post<any>(`${this.apiURL}api/CoreCache/GetAllCache`,body,this.header);
}
getClearCache(input:any){
  return this.httpService.post<any>(`${this.apiURL}api/CoreCache/Deletecorecache?keys=${input}`,this.header);
}
getCase360Parameter() {
  return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Case360Parameter`, this.header)
}

Case360search(searchTerm){
    
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseInfoByAccession`,searchTerm,this.header);
}
getvbjobsdetail(routeId: string, agentId: string) {
  return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetVbJobDetailsForDiagnostics?agentId=${agentId}&routeId=${routeId}`, this.header);
}
getFTP5vbjobsdetail(routeId: string, agentId: string) {
  return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetVbJobDetailsForFTPV5?agentId=${agentId}&routeId=${routeId}`, this.header);
}
repopulateCaseId(input:any){
  return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/ReGenerateCaseClaim`,input,this.header)
}

updateIntegrationMapping(body) {
  return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/UpdateIntegrationmapping`, body,this.header)
}

  GetVatraceLogs(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VaTraceLogs/GetVatraceLogs`, body, this.header);
  }

CreateIntegrationmapping(body) {
  return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/CreateIntegrationmapping`, body,this.header)
}

GetVBCheckPortExists(agentId: string,port: number) {
  return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetVBCheckPortExists?agentId=${agentId}&port=${port}`, this.header);
}

Getcomponentbyname(displayname:string){
  let obj=this.ComponentsList.find(V=>V.DisplayName===displayname.toLowerCase());
  if(obj!=undefined){
    return obj.componentname;
  }else{
    return '';
  }
  
}
GetCaseClaimDetails(body){
  return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseClaimDetails`,body,this.header)
}

 hl7VASpec = {
  "MSH": [
    {
      "segindex": 1,
      "segfield": "Field Separator",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Encoding Characters",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Sending Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Sending Facility",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Receiving Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Receiving Facility",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Message",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Message Type",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Message Control ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Processing ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Version ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Accept Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Application Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Country Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PID": [
    {
      "segindex": 1,
      "segfield": "Set ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Patient ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Patient Identifier List",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Alternate Patient ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Patient Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Birth",
      "segtype": "DOB",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Patient Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 13,
      "segfield": "Phone Number-Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Phone Number-Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "SSN Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ethnicity",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],

  "EVN":[
    {
      "segindex": 1,
      "segfield": "Event Type Code [ID]*",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
    "segindex": 2,
    "segfield": "Recorded Date/Time [TS]",
    "segtype": "",
    "segvalue": "",
    "segcomponent": []
    }
  ],
  "ZEF":[
    {
      "segindex": 1,
      "segfield": "Set ID - ZEF",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Embedded Content",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Sending Application",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " Encoding Technology ",

          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
    ]
    },

  ],
  "PV1": [
    {
      "segindex": 1,
      "segfield": "Set ID-PV1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
      ]
    },
    {
      "segindex": 7,
      "segfield": "Attending Doctor",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Ordering Physician NPI",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Ordering Physician Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Ordering Physician First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Prefix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 7,
          "segfield": "Degree",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Referring Doctor",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Visit Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN1": [
    {
      "segindex": 1,
      "segfield": "Set ID-IN1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insurance Plan ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Insurance Company ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Insurance Company Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Insurance Company Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Group Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Plan Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Name of Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 17,
      "segfield": "Insured's Relationship to Patient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Insured's Date of Birth",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "Policy Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "Insured's Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "GT1": [
    {
      "segindex": 1,
      "segfield": "Set ID-GT1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Guarantor Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 5,
      "segfield": "Guarantor Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 6,
      "segfield": "Guarantor Ph Num –Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Guarantor Ph Num Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Guarantor Relationship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ORC": [
    {
      "segindex": 1,
      "segfield": "Order Control",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Placer Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Parent Order",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Date/Time of Transaction",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 21,
      "segfield": "Ordering Facility Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ordering Facility Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Ordering Facility Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Ordering Provider Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "OBR": [
    {
      "segindex": 1,
      "segfield": "Set ID - OBR",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Universal Service Identifier",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Order Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Case Type Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "Alternate Identifier",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Alternate Text",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Name of Alternate Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Observation Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Collection Volume",
      "segtype": "CollectionDate",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Collector Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "Relevant Clinical Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Specimen Received Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Specimen Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 18,
      "segfield": "Placer Field 1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Placer Field 2",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Filler Field 1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Filler Field 2",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Results Rpt/Status Chng - Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Diagnostic Serv Sect ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Result Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Result Copies To",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Parent Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Technician",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Transcriptionist",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "Number of Sample Containers *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Transport Logistics of Collected Sample",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "Collector's Comment *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "Procedure Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Procedure Code Modifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Placer Supplemental Service Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Filler Supplemental Service Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "Number of Sample Containers *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "DG1": [
    {
      "segindex": 1,
      "segfield": "Set ID-DG1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Diagnosis Coding Method",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Diagnosis Code - DG1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ICD Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Description",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    }
  ],
  "OBX": [
    {
      "segindex": 1,
      "segfield": "Set ID - OBX",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Value Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 3,
      "segfield": "Observation Identifier",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Identifier",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Text",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": " Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }

      ]
  },
   {
      "segindex": 4,
      "segfield": "Observation Sub-ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },




    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": " Sending Application",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": " Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " - Encoding Technology",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
		 {
          "segindex": 5,
          "segfield": " - Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },

      ]
    },
	{
      "segindex": 6,
      "segfield": "Units",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "References Range",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Abnormal Flags",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Observation Result status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Date/Time of the Observation",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Responsible Observer",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Equipment Instance Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Date/Time of the  Analysis",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Performing Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Performing Organization Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Performing Organization Medical Director ",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],

  "NTE": [
    {
      "segindex": 1,
      "segfield": "Set ID-NTE",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Source of Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Comment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZPS": [
    {
      "segindex": 1,
      "segfield": "Set ID-ZPS",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Physician Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN2": [
    {
      "segindex": 1,
      "segfield": "Insured's Employee ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insured's Social Security Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "	Insured's Employer's Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Employer Information Data",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Mail Claim Party",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Medicare Health Ins Card Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Medicaid Case Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "	Medicaid Case Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Military Sponsor Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Military ID Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "	Dependent Of Military Recipient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "	Military Organization",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "	Military Station",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Military Service",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Military Rank/Grade",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Military Retire Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Baby Coverage",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Combine Baby Bill",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Blood Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Special Coverage Approval Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Special Coverage Approval Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex":24,
      "segfield": "Non-Covered Insurance Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Payor ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 26,
      "segfield": "	Payor Subscriber ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 27,
      "segfield": "Eligibility Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Room Coverage Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Policy Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 30,
      "segfield": "Daily Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 31,
      "segfield": "Living Dependency",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 32,
      "segfield": "Ambulatory Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 33,
      "segfield": "Citizenship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Primary Language",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Living Arrangement",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "	Publicity Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "	Protection Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Student Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "	Religion",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 40,
      "segfield": "Mother's Maiden Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 41,
      "segfield": "Sequence Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 42,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Employment Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Job Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Job Code/Class",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 48,
      "segfield": "Job Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 49,
      "segfield": "Employer Contact Person Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 50,
      "segfield": "Employer Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 51,
      "segfield": "Employer Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 52,
      "segfield": "Insured's Contact Person's Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 53,
      "segfield": "Insured's Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 54,
      "segfield": "Insured's Contact Person Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 55,
      "segfield": "Relationship to the Patient Start Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 56,
      "segfield": "Relationship to the Patient Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 57,
      "segfield": "Insurance Co Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 58,
      "segfield": "Insurance Co Contact Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 59,
      "segfield": "Policy Scope",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 60,
      "segfield": "Policy Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 61,
      "segfield": "Patient Member Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 62,
      "segfield": "Guarantor's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 63,
      "segfield": "Insured's Phone Number - Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 64,
      "segfield": "Insured's Employer Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 65,
      "segfield": "Military Handicapped Program",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 66,
      "segfield": "Suspend Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 67,
      "segfield": "Copay Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 68,
      "segfield": "Stoploss Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 69,
      "segfield": "	Insured Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 70,
      "segfield": "Insured Employer Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 71,
      "segfield": "	Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 72,
      "segfield": "Patient's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 73,
      "segfield": "Co-Pay Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

  ]
};

 ORU={
  "MSH": [
    {
      "segindex": 1,
      "segfield": "Field Separator",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Encoding Characters",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Sending Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Sending Facility",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Receiving Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Receiving Facility",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Message",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Message Type",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Message Control ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Processing ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Version ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Accept Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Application Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Country Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PID": [
    {
      "segindex": 1,
      "segfield": "Set ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Patient ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Patient Identifier List",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Alternate Patient ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Patient Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Birth",
      "segtype": "DOB",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Patient Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 13,
      "segfield": "Phone Number-Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Phone Number-Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "SSN Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ethnicity",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PV1": [
    {
      "segindex": 1,
      "segfield": "Set ID-PV1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
      ]
    },
    {
      "segindex": 7,
      "segfield": "Attending Doctor",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Ordering Physician NPI",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Ordering Physician Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Ordering Physician First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Prefix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 7,
          "segfield": "Degree",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Referring Doctor",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Visit Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "DG1": [
    {
      "segindex": 1,
      "segfield": "Set ID-DG1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Diagnosis Coding Method",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Diagnosis Code - DG1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ICD Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Description",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    }
  ],
  "NTE": [
    {
      "segindex": 1,
      "segfield": "Set ID-NTE",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Source of Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Comment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZEF":[
    {
      "segindex": 1,
      "segfield": "Set ID - ZEF",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Embedded Content",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Sending Application",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " Encoding Technology ",

          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
    ]
    },

  ],
  "OBR": [
    {
      "segindex": 1,
      "segfield": "Set ID - OBR",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Universal Service Identifier",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Order Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Case Type Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }

      ]
    },
    {
      "segindex": 7,
      "segfield": "Observation Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

    {
      "segindex": 13,
      "segfield": "Relevant Clinical Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Specimen Received Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Specimen Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },

    {
      "segindex": 22,
      "segfield": "Results Rpt/Status Chng - Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

    {
      "segindex": 25,
      "segfield": "Result Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

    {
      "segindex": 37,
      "segfield": "Number of Sample Containers *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

    {
      "segindex": 46,
      "segfield": "Placer Supplemental Service Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Filler Supplemental Service Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

  ],
  "OBX": [
    {
      "segindex": 1,
      "segfield": "Set ID - OBX",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Value Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 3,
      "segfield": "Observation Identifier",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Identifier",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Text",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": " Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }

      ]
  },
   {
      "segindex": 4,
      "segfield": "Observation Sub-ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },




    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": " Sending Application",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": " Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " - Encoding Technology",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
		 {
          "segindex": 5,
          "segfield": " - Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },

      ]
    },
	{
      "segindex": 6,
      "segfield": "Units",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "References Range",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Abnormal Flags",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Observation Result status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Date/Time of the Observation",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Responsible Observer",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Equipment Instance Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Date/Time of the  Analysis",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Performing Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Performing Organization Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Performing Organization Medical Director ",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ORC": [
    {
      "segindex": 1,
      "segfield": "Order Control",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Placer Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Parent Order",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Date/Time of Transaction",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 21,
      "segfield": "Ordering Facility Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ordering Facility Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Ordering Facility Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Ordering Provider Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZPS": [
    {
      "segindex": 1,
      "segfield": "Set ID-ZPS",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Physician Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN2": [
    {
      "segindex": 1,
      "segfield": "Insured's Employee ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insured's Social Security Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "	Insured's Employer's Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Employer Information Data",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Mail Claim Party",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Medicare Health Ins Card Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Medicaid Case Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "	Medicaid Case Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Military Sponsor Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Military ID Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "	Dependent Of Military Recipient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "	Military Organization",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "	Military Station",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Military Service",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Military Rank/Grade",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Military Retire Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Baby Coverage",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Combine Baby Bill",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Blood Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Special Coverage Approval Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Special Coverage Approval Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex":24,
      "segfield": "Non-Covered Insurance Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Payor ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 26,
      "segfield": "	Payor Subscriber ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 27,
      "segfield": "Eligibility Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Room Coverage Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Policy Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 30,
      "segfield": "Daily Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 31,
      "segfield": "Living Dependency",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 32,
      "segfield": "Ambulatory Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 33,
      "segfield": "Citizenship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Primary Language",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Living Arrangement",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "	Publicity Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "	Protection Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Student Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "	Religion",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 40,
      "segfield": "Mother's Maiden Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 41,
      "segfield": "Sequence Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 42,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Employment Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Job Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Job Code/Class",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 48,
      "segfield": "Job Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 49,
      "segfield": "Employer Contact Person Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 50,
      "segfield": "Employer Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 51,
      "segfield": "Employer Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 52,
      "segfield": "Insured's Contact Person's Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 53,
      "segfield": "Insured's Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 54,
      "segfield": "Insured's Contact Person Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 55,
      "segfield": "Relationship to the Patient Start Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 56,
      "segfield": "Relationship to the Patient Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 57,
      "segfield": "Insurance Co Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 58,
      "segfield": "Insurance Co Contact Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 59,
      "segfield": "Policy Scope",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 60,
      "segfield": "Policy Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 61,
      "segfield": "Patient Member Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 62,
      "segfield": "Guarantor's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 63,
      "segfield": "Insured's Phone Number - Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 64,
      "segfield": "Insured's Employer Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 65,
      "segfield": "Military Handicapped Program",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 66,
      "segfield": "Suspend Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 67,
      "segfield": "Copay Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 68,
      "segfield": "Stoploss Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 69,
      "segfield": "	Insured Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 70,
      "segfield": "Insured Employer Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 71,
      "segfield": "	Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 72,
      "segfield": "Patient's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 73,
      "segfield": "Co-Pay Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

  ]
}
 DFT={
  "MSH": [
    {
      "segindex": 1,
      "segfield": "Field Separator",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Encoding Characters",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Sending Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Sending Facility",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Receiving Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Receiving Facility",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Message",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Message Type",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Message Control ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Processing ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Version ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Accept Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Application Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Country Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PID": [
    {
      "segindex": 1,
      "segfield": "Set ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Patient ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Patient Identifier List",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Alternate Patient ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Patient Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Birth",
      "segtype": "DOB",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Patient Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 13,
      "segfield": "Phone Number-Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Phone Number-Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "SSN Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ethnicity",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PV1": [
    {
      "segindex": 1,
      "segfield": "Set ID-PV1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
      ]
    },
    {
      "segindex": 7,
      "segfield": "Attending Doctor",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Ordering Physician NPI",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Ordering Physician Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Ordering Physician First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Prefix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 7,
          "segfield": "Degree",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Referring Doctor",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Visit Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "DG1": [
    {
      "segindex": 1,
      "segfield": "Set ID-DG1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Diagnosis Coding Method",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Diagnosis Code - DG1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ICD Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Description",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    }
  ],
  "GT1": [
    {
      "segindex": 1,
      "segfield": "Set ID-GT1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Guarantor Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 5,
      "segfield": "Guarantor Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 6,
      "segfield": "Guarantor Ph Num –Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Guarantor Ph Num Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Guarantor Relationship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN1": [
    {
      "segindex": 1,
      "segfield": "Set ID-IN1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insurance Plan ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Insurance Company ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Insurance Company Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Insurance Company Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Group Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Plan Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Name of Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 17,
      "segfield": "Insured's Relationship to Patient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Insured's Date of Birth",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "Policy Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "Insured's Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
	{
      "segindex": 47,
      "segfield": "Coverage Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],

  "FT1": [
    {
      "segindex": 1,
      "segfield": "Set ID – FT1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Transaction ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Transaction Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Transaction Posting Date",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 6,
      "segfield": "Transaction Type",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 10,
      "segfield": "Transaction Quantity",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 13,
      "segfield": "Department Code",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 16,
      "segfield": "Patient Location",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
	{
      "segindex": 19,
      "segfield": "Diagnosis ICD Code",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Performed By",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ID number",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Suffix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
     {
      "segindex": 21,
      "segfield": "Ordered By",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ID number",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Suffix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 23,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Procedure Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 26,
      "segfield": "Procedure Code Modifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
       }
  ],
  "NTE": [
    {
      "segindex": 1,
      "segfield": "Set ID-NTE",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Source of Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Comment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZEF":[
    {
      "segindex": 1,
      "segfield": "Set ID - ZEF",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Embedded Content",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Sending Application",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " Encoding Technology ",

          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
    ]
    },

  ],
  "OBX": [
    {
      "segindex": 1,
      "segfield": "Set ID - OBX",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Value Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
	 {
      "segindex": 3,
      "segfield": "Observation Identifier",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Identifier",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Text",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": " Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }

      ]
  },
   {
      "segindex": 4,
      "segfield": "Observation Sub-ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },




    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": " Sending Application",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": " Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " - Encoding Technology",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
		 {
          "segindex": 5,
          "segfield": " - Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },

      ]
    },
	{
      "segindex": 6,
      "segfield": "Units",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "References Range",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Abnormal Flags",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Observation Result status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Date/Time of the Observation",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Responsible Observer",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Equipment Instance Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Date/Time of the  Analysis",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Performing Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Performing Organization Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Performing Organization Medical Director ",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZPS": [
    {
      "segindex": 1,
      "segfield": "Set ID-ZPS",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Physician Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN2": [
    {
      "segindex": 1,
      "segfield": "Insured's Employee ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insured's Social Security Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "	Insured's Employer's Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Employer Information Data",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Mail Claim Party",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Medicare Health Ins Card Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Medicaid Case Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "	Medicaid Case Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Military Sponsor Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Military ID Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "	Dependent Of Military Recipient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "	Military Organization",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "	Military Station",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Military Service",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Military Rank/Grade",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Military Retire Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Baby Coverage",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Combine Baby Bill",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Blood Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Special Coverage Approval Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Special Coverage Approval Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex":24,
      "segfield": "Non-Covered Insurance Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Payor ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 26,
      "segfield": "	Payor Subscriber ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 27,
      "segfield": "Eligibility Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Room Coverage Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Policy Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 30,
      "segfield": "Daily Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 31,
      "segfield": "Living Dependency",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 32,
      "segfield": "Ambulatory Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 33,
      "segfield": "Citizenship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Primary Language",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Living Arrangement",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "	Publicity Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "	Protection Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Student Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "	Religion",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 40,
      "segfield": "Mother's Maiden Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 41,
      "segfield": "Sequence Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 42,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Employment Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Job Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Job Code/Class",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 48,
      "segfield": "Job Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 49,
      "segfield": "Employer Contact Person Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 50,
      "segfield": "Employer Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 51,
      "segfield": "Employer Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 52,
      "segfield": "Insured's Contact Person's Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 53,
      "segfield": "Insured's Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 54,
      "segfield": "Insured's Contact Person Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 55,
      "segfield": "Relationship to the Patient Start Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 56,
      "segfield": "Relationship to the Patient Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 57,
      "segfield": "Insurance Co Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 58,
      "segfield": "Insurance Co Contact Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 59,
      "segfield": "Policy Scope",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 60,
      "segfield": "Policy Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 61,
      "segfield": "Patient Member Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 62,
      "segfield": "Guarantor's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 63,
      "segfield": "Insured's Phone Number - Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 64,
      "segfield": "Insured's Employer Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 65,
      "segfield": "Military Handicapped Program",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 66,
      "segfield": "Suspend Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 67,
      "segfield": "Copay Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 68,
      "segfield": "Stoploss Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 69,
      "segfield": "	Insured Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 70,
      "segfield": "Insured Employer Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 71,
      "segfield": "	Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 72,
      "segfield": "Patient's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 73,
      "segfield": "Co-Pay Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

  ]
}
  BAR={
  "MSH": [
    {
      "segindex": 1,
      "segfield": "Field Separator",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Encoding Characters",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Sending Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Sending Facility",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Receiving Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Receiving Facility",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Message",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Message Type",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Message Control ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Processing ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Version ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Accept Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Application Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Country Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PID": [
    {
      "segindex": 1,
      "segfield": "Set ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Patient ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Patient Identifier List",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Alternate Patient ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Patient Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Birth",
      "segtype": "DOB",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Patient Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 13,
      "segfield": "Phone Number-Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Phone Number-Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "SSN Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ethnicity",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PV1": [
    {
      "segindex": 1,
      "segfield": "Set ID-PV1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
      ]
    },
    {
      "segindex": 7,
      "segfield": "Attending Doctor",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Ordering Physician NPI",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Ordering Physician Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Ordering Physician First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Prefix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 7,
          "segfield": "Degree",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Referring Doctor",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Visit Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "DG1": [
    {
      "segindex": 1,
      "segfield": "Set ID-DG1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Diagnosis Coding Method",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Diagnosis Code - DG1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ICD Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Description",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    }
  ],
  "GT1": [
    {
      "segindex": 1,
      "segfield": "Set ID-GT1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Guarantor Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 5,
      "segfield": "Guarantor Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 6,
      "segfield": "Guarantor Ph Num –Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Guarantor Ph Num Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Guarantor Relationship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN1": [
    {
      "segindex": 1,
      "segfield": "Set ID-IN1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insurance Plan ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Insurance Company ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Insurance Company Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Insurance Company Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Group Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Plan Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Name of Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 17,
      "segfield": "Insured's Relationship to Patient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Insured's Date of Birth",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "Policy Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "Insured's Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
	{
      "segindex": 47,
      "segfield": "Coverage Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZPS": [
    {
      "segindex": 1,
      "segfield": "Set ID-ZPS",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Physician Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN2": [
    {
      "segindex": 1,
      "segfield": "Insured's Employee ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insured's Social Security Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "	Insured's Employer's Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Employer Information Data",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Mail Claim Party",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Medicare Health Ins Card Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Medicaid Case Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "	Medicaid Case Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Military Sponsor Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Military ID Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "	Dependent Of Military Recipient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "	Military Organization",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "	Military Station",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Military Service",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Military Rank/Grade",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Military Retire Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Baby Coverage",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Combine Baby Bill",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Blood Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Special Coverage Approval Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Special Coverage Approval Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex":24,
      "segfield": "Non-Covered Insurance Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Payor ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 26,
      "segfield": "	Payor Subscriber ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 27,
      "segfield": "Eligibility Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Room Coverage Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Policy Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 30,
      "segfield": "Daily Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 31,
      "segfield": "Living Dependency",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 32,
      "segfield": "Ambulatory Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 33,
      "segfield": "Citizenship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Primary Language",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Living Arrangement",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "	Publicity Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "	Protection Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Student Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "	Religion",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 40,
      "segfield": "Mother's Maiden Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 41,
      "segfield": "Sequence Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 42,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Employment Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Job Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Job Code/Class",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 48,
      "segfield": "Job Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 49,
      "segfield": "Employer Contact Person Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 50,
      "segfield": "Employer Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 51,
      "segfield": "Employer Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 52,
      "segfield": "Insured's Contact Person's Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 53,
      "segfield": "Insured's Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 54,
      "segfield": "Insured's Contact Person Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 55,
      "segfield": "Relationship to the Patient Start Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 56,
      "segfield": "Relationship to the Patient Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 57,
      "segfield": "Insurance Co Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 58,
      "segfield": "Insurance Co Contact Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 59,
      "segfield": "Policy Scope",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 60,
      "segfield": "Policy Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 61,
      "segfield": "Patient Member Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 62,
      "segfield": "Guarantor's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 63,
      "segfield": "Insured's Phone Number - Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 64,
      "segfield": "Insured's Employer Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 65,
      "segfield": "Military Handicapped Program",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 66,
      "segfield": "Suspend Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 67,
      "segfield": "Copay Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 68,
      "segfield": "Stoploss Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 69,
      "segfield": "	Insured Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 70,
      "segfield": "Insured Employer Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 71,
      "segfield": "	Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 72,
      "segfield": "Patient's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 73,
      "segfield": "Co-Pay Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

  ],
  "ORC": [
    {
      "segindex": 1,
      "segfield": "Order Control",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Placer Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Parent Order",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Date/Time of Transaction",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 21,
      "segfield": "Ordering Facility Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ordering Facility Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Ordering Facility Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Ordering Provider Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
}

}

