import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { NewauditComponent } from '../../app/newaudit/newaudit.component';

@Component({
  selector: 'app-userrole',
  templateUrl: './userrole.component.html',
  styleUrls: ['./userrole.component.scss']
})
export class UserroleComponent implements OnInit {
  screenHeight: number;
  selectedusername: any;
  PrimaryRoleName:any;

  constructor(
    private _fb: FormBuilder,
    public service: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal) {
    this.reactiveFormuser = _fb.group({ username: '' });
    this.reactiveFormsearchuser = _fb.group({ usernamesearch: '' });
  }

  async ngOnInit() {
    this.screenHeight = window.innerHeight - 229;
    this.ngxUiLoaderService.start();
    await this.getroles();
    // await this.getADUserList("");
    this.ngxUiLoaderService.stop();
  }

  //search page
  reactiveFormsearchuser: FormGroup;
  UserListsearch: any = [];
  isLoadingsearchuser: boolean = false;
  notfounddatasearch: boolean;
  rolesdropdownsearchSettings = {
    singleSelection: false,
    idField: "RoleId",
    textField: "RoleName",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
    searchPlaceholderText: 'Select'
  };

  async selectedsearchuser(event: any) {
    this.selecteduserId = event.UserId;
  }
  async getADUsersearchList(event: any) {
    this.isLoadingsearchuser = true;
    var apiname = "api/UserRoleConfig/GetADUserList?userid=" + event;
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.UserListsearch = data as any[];
        this.isLoadingsearchuser = false;
        if (data.length == 0) {
          this.notfounddatasearch = true;
        } else {
          this.notfounddatasearch = false;
        }
      },
      error: error => {
        this.isLoadingsearchuser = false;
        this.notfounddatasearch = true;
      }
    });

  }

  searchuserlistsearchCleared(event) {
    this.isLoadingsearchuser = false;
    this.UserListsearch = [];
    this.notfounddatasearch = false;
  }

  rolesdropdownsearchList: any = [];
  selectedsearchItems = [];
  selectedsearchrolesItems: any = [];

  onItemsearchSelect(item: any) {
    this.selectedsearchrolesItems = this.selectedsearchItems;
  }

  onsearchSelectAll(items: any) {
    this.selectedsearchrolesItems = items;
  }
  resetsearch() {
    this.reactiveFormsearchuser.reset();
    this.notfounddatasearch = false;
    this.selectedsearchrolesItems = [];
    this.selectedsearchItems = [];
    this.userroleslist = [];
    this.selecteduserId='';
  }

  async open(content, editdata) {
    await this.reset();
    await this.selecteduser(editdata);
    this.modalService.open(content, { backdrop: 'static', windowClass: "myModalTemplate" }).result.then((result) => {

    }, (reason) => {

    });
  }
  userroleslist: any = [];

  async SearchUserRoles() {
    this.ngxUiLoaderService.start();

    var userdata = {
      "UserId": this.selecteduserId,
      "RoleData": this.selectedsearchItems
    }
    var apiname = 'api/UserRoleConfig/SearchUserRoles';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, userdata);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.ngxUiLoaderService.stop();
      this.userroleslist = response as any[];
      if (this.userroleslist.length === 0) {
        this.toastr.warning("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
  }

  //search page


  userid: string = '';
  rolesdropdownList: any = [];
  rolesearchControl = new FormControl();

  async getroles() {
    var apiname = "api/UserRoleConfig/GetRoles";
    let resultset = await this.service.getapi(apiname);
    this.rolesdropdownList = resultset;
    this.rolesdropdownList.forEach(element => {
      element.selected = false;
    });
    this.rolesdropdownsearchList = resultset;
  }

  reactiveFormuser: FormGroup;
  UserList: any = [];
  keywordsearch = "UserId";
  isLoadinguser: boolean = false;
  notfounddata: boolean;

  async getADUserList(event: any) {
    if (event) {
      this.isLoadinguser = true;
      var apiname = "api/UserRoleConfig/GetADUserList?userid=" + event;
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.UserList = data as any[];
          this.isLoadinguser = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadinguser = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selecteduserId: string = '';

  async selecteduser(event: any) {
    var val = event;
    if (val) {
      this.selecteduserId = val.UserId;
      this.selectedusername= val.UserId;
      this.ngxUiLoaderService.start();
      var apiname = "api/UserRoleConfig/GetSelectedUserRoles?UserId=" + val.UserId;
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          if (data.length > 0) {
            this.notfounddata = false;
            var response = data as any[];
            this.reactiveFormuser.patchValue({
              username: response[0].UserName
            });
            this.selecteduserId = response[0].UserId;
            this.selectedusername= response[0].UserName;
            this.rolesdropdownList.forEach(elementroot => {
              elementroot.selected = false;
              response.forEach(element => {
                if (elementroot.RoleId == element.RoleId) {
                  elementroot.selected = true;
                }
              });
            });
            this.primaryrolesList = [];
            this.primaryrolesList = this.rolesdropdownList.filter(a => a.selected == true);
            const primaryroledata = response.filter(a => a.PrimaryRoleId == 1)[0].RoleId;
            this.primarRoleselected = Number(primaryroledata);
            if (this.primaryrolesList.length == 1) {
              this.primaryrolesList.forEach(element => {
                element.checked = true;
              });
            }
            else {
              this.primaryrolesList.forEach(obj => {
                if (obj.RoleId == Number(primaryroledata)) {
                  obj.checked = true;
                }
                else {
                  obj.checked = false;
                }
              });
            }

          } else {
            this.notfounddata = true;

          }

          this.ngxUiLoaderService.stop();
        },
        error: error => {
          this.notfounddata = true;
          this.ngxUiLoaderService.stop();
        }
      });
    }
  }

  searchuserlistCleared(event) {
    this.isLoadinguser = false;
    this.UserList = [];
    this.notfounddata = false;
  }

  reset() {
    this.reactiveFormuser.reset();
    this.notfounddata = false;
    this.checkallroles(false);
    this.checkall = false;
    this.primaryrolesList = [];
    this.primarRoleselected = "";
    this.selecteduserId = '';
    this.selectedusername='';
  }

  async SaveUserRoles(c) {
    this.ngxUiLoaderService.start();
    var returnvalue = await this.validationchecks();
    if (returnvalue == true) {
      var userdata = {
        "UserId": this.selectedusername,
        "Modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        "RoleData": this.primaryrolesList.map(w => {
          return {
            RoleName: w.RoleName,
            RoleId: w.RoleId
          };
        }),
        "PrimaryRoleId": Number(this.primarRoleselected),
        "PrimaryRoleName":this.PrimaryRoleName
      }
      var apiname = 'api/UserRoleConfig/SaveUserRoles';
      this.ngxUiLoaderService.start();
      let response = await this.service.searchpostapi(apiname, userdata);
      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.success("User roles mapped successfully", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.autoclosetemplate(c);
        await this.SearchUserRoles();
      }
    }
    else {
      this.ngxUiLoaderService.stop();
    }

  }
  autoclosetemplate(c) {
    setTimeout(() => {
      c('close modal');
      this.reset();
    }, 3000);
  }

  async validationchecks() {

    if (this.selecteduserId == "") {
      this.toastr.warning('User Id is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }

    if (this.primaryrolesList.length == 0) {
      this.toastr.warning('Role is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }

    let primaryrolecheck = false;
    this.primaryrolesList.forEach(objele => {
      if (objele.checked) {
        primaryrolecheck = true;
      }
    });

    if (!primaryrolecheck || this.primarRoleselected == undefined || this.primarRoleselected == '') {
      this.toastr.warning('Primary Role is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }

    return true;

  }

  closetpopup() {
    this.activeModal.close();
    this.reset();
  }
  closepopup(c) {
    c('close modal');
    this.reset();
  }
  checkall: boolean = false;

  primaryrolesList: any = [];
  addtoprimary() {
    this.primaryrolesList = [];
    this.primaryrolesList = this.rolesdropdownList.filter(a => a.selected == true);
    if (this.primaryrolesList.length == 1) {
      this.primaryrolesList.forEach(element => {
        element.checked = true;
      });
      this.primaryrolechange(this.primaryrolesList[0],this.primaryrolesList[0].RoleId)
    }
    else {
      this.primaryrolesList.forEach(obj => {
        if (Number(this.primarRoleselected) == obj.RoleId) {
          obj.checked = true;
          this.PrimaryRoleName = obj.RoleName;
        }
        else {
          obj.checked = false;
        }
      });
    }
  }
  checkallroles(checkallvalue) {
    this.rolesdropdownList.forEach(element => {
      element.selected = checkallvalue;
    });
    this.primaryrolesList = [];
    this.primaryrolesList = this.rolesdropdownList.filter(a => a.selected == true);

    if (this.primaryrolesList.length == 1) {
      this.primaryrolesList.forEach(element => {
        element.checked = true;
      });
      this.primaryrolechange(this.primaryrolesList[0],this.primaryrolesList[0].RoleId)
    }
    else {
      this.primaryrolesList.forEach(obj => {
        if (Number(this.primarRoleselected) == obj.RoleId) {
          obj.checked = true;
        }
        else {
          obj.checked = false;
        }
      });
    }

  }
  primarRoleselected: any;


  primaryrolechange(dataarray, eventvalue) {

    this.primarRoleselected = eventvalue;

    if (dataarray) {
      this.primaryrolesList.forEach(objele => {
        objele.checked = false;
      });

      dataarray.checked = true;
      this.PrimaryRoleName = dataarray.RoleName;

    }
  }

  openAuditPopUp(UserID:number , RoleName: string) {

    if (UserID !== null && RoleName !== '') {
      const auditDataToModal = {
        id: UserID,
        title: RoleName,
        context: "UserRole",
        guid: '',
        type: "Name",
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(data => {

      });
    }
  }
  optionClick(event) {
    // this.onAddNewCard().clear();
    this.selectedsearchrolesItems = event.value;
  }


}
