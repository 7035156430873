import { V } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-caseclaim',
  templateUrl: './caseclaim.component.html',
  styleUrls: ['./caseclaim.component.scss']
})
export class CaseclaimComponent {
// caseclaimdetails:any[]=[{ 
//   "ClaimNumber":  "claim12222243", 
//   "AccessionNumber" :  "acc12-345", 
//   "ClaimState":"Verified", 
//   "MilestoneDetails" : [ 
//    { 
//    "Milestone":  "Claim Created", 
//    "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//    "PerformedDate" : "06-01-2025", 
//    "Notes" : "Demographics is completed for the case  Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case Demographics is completed for the case", 
//    "Sequence": "1" 
//   } , 
//   { 
//   "Milestone":  "Codes Verified", 
//   "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//   "PerformedDate" : "06-01-2025", 
//   "Notes" : "Demographics is completed for the case" ,
//   "Sequence": "3" 
//  } , 
//  { 
//  "Milestone":  "Queued for Submission", 
//  "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//  "PerformedDate" : "06-01-2025", 
//  "Notes" : "Demographics is completed for the case" ,
//  "Sequence": "4" 
// } ,
//    { 
//    "Milestone":  "Charges Verified", 
//    "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//    "PerformedDate" : "06-01-2025", 
//    "Notes" : "Demographics is completed for the case" ,
//    "Sequence": "2" 
//   }
//   ] 
//    },
//    { 
//     "ClaimNumber":  "claim12222243", 
//     "AccessionNumber" :  "acc12-345", 
//     "ClaimState":"Verified", 
//     "MilestoneDetails" : [ 
//      { 
//      "Milestone":  "Claim Created", 
//      "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//      "PerformedDate" : "06-01-2025", 
//      "Notes" : "Demographics is completed for the case", 
//      "Sequence": "1" 
//     } , 
//     { 
//     "Milestone":  "Codes Verified", 
//     "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//     "PerformedDate" : "06-01-2025", 
//     "Notes" : "Demographics is completed for the case" ,
//     "Sequence": "3" 
//    } , 
//    { 
//    "Milestone":  "Queued for Submission", 
//    "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//    "PerformedDate" : "06-01-2025", 
//    "Notes" : "Demographics is completed for the case" ,
//    "Sequence": "4" 
//   } ,
//      { 
//      "Milestone":  "Charges Verified", 
//      "PerformedBy" : "jyothiswaroop.r@starmarkit.com", 
//      "PerformedDate" : "06-01-2025", 
//      "Notes" : "Demographics is completed for the case" ,
//      "Sequence": "2" 
//     }
//     ] 
//      }];
caseclaimdetails:any[]=[];
@Input() CaseDetails={};
isexpanded:boolean;
constructor(private service:CommonService,private toastr:ToastrService){}
ngOnInit() {
let labguid=this.CaseDetails["ServiceProvider"]['Type']=='Laboratory'?this.CaseDetails["ServiceProvider"]['Location']['OrganizationGUID']:''
let obj={
 "CaseGUID": this.CaseDetails["CaseGUID"], 
 "DeploymentKey": this.CaseDetails["DeploymentKey"] , 
 "LabGUID":labguid
}
this.getCaseClaimDetails(obj);
}
getCaseClaimDetails(obj){
this.service.GetCaseClaimDetails(obj).subscribe((data:any)=>{
  if(data!=null){
    debugger
      if(data['Status'].toLowerCase()!='success'){
        this.toastr.warning(data['Message'], "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        })
      }else{
        this.caseclaimdetails=data['Data'];
        this.caseclaimdetails.forEach(V=>{
          V['MilestoneDetails'].sort((a,b)=>a.Sequence-b.Sequence);
        });
      }
  }
})
}
expand(){
  this.isexpanded=!this.isexpanded;
}
}
