import { Component,ViewChild} from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})


export class DocumentsComponent {

  @ViewChild("fileInputlhs", { static: false }) myFileInputlhs;
  isChecked:boolean;
  documentlist:any[]=[];
  uploadedfile:any='';
  ngOnInit() {
    this.GetSearch();
    
  }

  async addfilelhs(e){
    this.uploadedfile=e.target.files[0];
    const filename = e.target.files[0].name;
    
  }

  GetSearch(){
   var obj = {
    "filename" : "Test.pdf",
    "pdfSrc" : "C:\Users\vijaykumar.r\Pictures\Screenshots\Test93203936.pdf"
   }
   this.documentlist.push(obj);
   }

   selectedRow(data,obj)
  {
    if(obj != null){
    obj['ischecked']=data.checked;
    let checkedList = this.documentlist.filter(V=>V.ischecked==true);
   if(checkedList.length >0){
    this.isChecked =true;
   }
   else{
    this.isChecked=false;
   }
  }
  else{
    this.isChecked=false;
  }
  }

  downloadFile(data,obj) {
    let filteredData=this.documentlist.filter(V=>V.ischecked==true);
    let newdata=this.documentlist.filter(V=>V.ischecked!=true);
    let finalString='';
    let imageString='';
    filteredData.forEach(V=>{
      finalString+=V['filename'];
      imageString+=V['pdfSrc'];
    });
  }

}

