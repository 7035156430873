import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';


declare const copyToClipboard: any;

@Component({
  selector: 'app-integrationmapping',
  templateUrl: './integrationmapping.component.html',
  styleUrls: ['./integrationmapping.component.scss']
})
export class IntegrationmappingComponent implements OnInit {
  deploymentItemsList = [];
  keyword = 'name';
  searchKeyword: string = '';
  previousmapping: {};
  maxContentHeight: string = '67vh'; // Initial max-height value

  public reactiveFormdeployment: FormGroup;
  deploymentSelected: any;
  reactiveFormmapping: FormGroup;
  xmlmappinglist: any;
  enabledownloadmappings: boolean;
  lookuphandler: any;
  mappingmodel = { Handler: '', LookupType: '', LookupId: '', LookupValue: '' };
  addmapping: any;
  updatemapping: any;
  closelookup: boolean;
  closeResponse: boolean;
  FormFlag: boolean = false;

  pageSize = 10; // Initial page size
  totalItems = 0; // Total number of items
  currentPageIndex = 1; // Current page index

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  failedMessageNote: string;
  editdeployment: boolean = false;

  constructor(public service: CommonService,
    private activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private route: ActivatedRoute) {
    this.reactiveFormdeployment = this._formBuilder.group({ deploymentname: ['', Validators.required] });
    this.xmlmappinglist = <any>[];

  }

  ngOnInit() {
    this.reactiveFormmapping = this._formBuilder.group({
      Handler: [''],
      LookupType: [''],
      LookupValue: [''],
      LookupId: ['']
    }),
      this.getDeployments();
  }



  getDeployments() {
    this.service.getDeployments("Deployments").subscribe(
      (data) => {
        if (data.length > 0) {
          this.deploymentItemsList = data;
        } else if (data.length === 0) {
          this.deploymentItemsList = [];
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  selectdeploymentEvent(item: any) {
    this.deploymentSelected = item;
  }

  onFocuseddeployment(event) {
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
  }


  cleareddeployment(event) {
    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
  }

  // Method to handle page change event
  onPageChange(event: any) {
    this.selectedRow = -1;
    // Update the current page index
    if (event.pageIndex == 0) {
      this.currentPageIndex = 1;
    } else {
      this.currentPageIndex = event.pageIndex + 1;
    }
    this.pageSize = event.pageSize;
    this.getxmlmappings();
  }

  async getxmlmappings() {
    this.selectedRow = -1;
    //this.clearmappings();
    //this.closelookup=true;
    if (this.deploymentSelected && this.searchKeyword != '') {
      var api: any = `api/VitalHubDXAPI/GetIntegrationmapping?LookUpData=${this.searchKeyword}&page=${this.currentPageIndex}&pageSize=${this.pageSize}`;
      this.ngxUiLoaderService.start()
      await this.service.dxgetapi(api, this.deploymentSelected).subscribe((data) => {
        if (data) {
          this.xmlmappinglist = data as mappingdata[];
        }
        if (this.xmlmappinglist.MappingList.length > 0) {
          this.totalItems = this.xmlmappinglist.TotalCount;
          //this.closelookup=false;
          this.closeResponse = false;
          this.FormFlag = false;
          this.maxContentHeight = '67vh'
          this.ngxUiLoaderService.stop();
        }
        else {
          this.closeResponse=true;
          this.ngxUiLoaderService.stop()
          this.toastr.warning("Failed to fetch data for given handler", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
      )
    }
    else if (this.deploymentSelected == undefined || this.deploymentSelected == '') {
      this.toastr.warning(
        "DeploymentKey is Mandatory",
        "",
        {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        }
      );
    }
    else {
      this.toastr.warning(
        "Enter HandlerName to be searched",
        "",
        {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        }
      );
    }

  }

  clearmappings() {
    this.reactiveFormmapping.reset();
    this.searchKeyword = '';
    this.mappingid = 0;
    this.selectedRow = -1;
    this.mappingmodel.Handler = '';
    this.mappingmodel.LookupType = '';
    this.mappingmodel.LookupId = '';
    this.mappingmodel.LookupValue = '';
    this.previousmapping = '';
    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
    this.FormFlag = false;
    this.xmlmappinglist.MappingList = [];
    this.currentPageIndex = 1;
    this.closelookup = true;
    this.closeResponse = true;

  }

  selectedRow: any;
  mappingid: any;
  editmapping(item: any, index: any, AddPopup: any) {

    this.ngxUiLoaderService.start()
    this.selectedRow = index;
    this.mappingid = item.Id || item.id;
    this.lookuphandler = item.handler
    // Update mappingmodel with item data
    this.mappingmodel = {
      Handler: item.Handler || item.handler,
      LookupType: item.LookupType || item.lookupType,
      LookupId: item.LookupId || item.lookupId,
      LookupValue: item.LookupValue || item.lookupValue
    };
    this.ngxUiLoaderService.stop()
    // Patch form value with mappingmodel
    this.reactiveFormmapping.patchValue(this.mappingmodel);
    this.closelookup = false;
    this.FormFlag = true;
    this.editdeployment = true;
    this.maxContentHeight = '67vh';
    this.modalService.open(AddPopup, { windowClass: "modalConfig", backdrop: 'static', keyboard: false });
  }

  async savemappings(h: any) {
    this.selectedRow = -1;
    this.mappingid = this.mappingid ? this.mappingid.toString() : "0";
    this.editdeployment = false;
    let objmapping: any;
    if (this.mappingmodel && this.mappingmodel.Handler && this.mappingmodel.LookupType && this.mappingmodel.LookupId && this.mappingmodel.LookupValue && this.deploymentSelected) {
      objmapping = {
        "id": this.mappingid.toString(),
        "handler": this.mappingmodel.Handler,
        "lookupType": this.mappingmodel.LookupType,
        "lookupId": this.mappingmodel.LookupId,
        "lookupValue": this.mappingmodel.LookupValue,
        "DeploymentKey": this.deploymentSelected
      };
    } else {
      objmapping = null;

      if (!this.deploymentSelected) {
        this.toastr.error('DeploymentKey is required.', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
      const fields = [
        { name: 'Handler', message: 'Handler is required.' },
        { name: 'LookupType', message: 'LookupType is required.' },
        { name: 'LookupId', message: 'LookupId is required.' },
        { name: 'LookupValue', message: 'LookupValue is required.' }
      ];


      for (const field of fields) {
        if (!this.mappingmodel[field.name]) {
          this.toastr.error(field.message, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
      }

    }

    if (objmapping != null) {
      if (this.mappingid !== "0") {
        await this.updatemappings(objmapping);
      } else {
        if (this.mappingid == "0") {
          this.addmappings(objmapping)
          this.reactiveFormdeployment.patchValue({ "deploymentname": this.deploymentSelected });
        }
      }
    }
    this.FormFlag = false;
    this.closepopup(h);
  }

  addmappings(objmapping) {

    if (this.deploymentSelected) {
      this.ngxUiLoaderService.start()
      this.service.CreateIntegrationmapping(objmapping).subscribe(data => {
        if (data.statusmessage != 'failure') {
          this.ngxUiLoaderService.stop()
          this.addmapping = data
          this.toastr.success("Integration Mapping Created Successfully", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          })
        } else {
          this.ngxUiLoaderService.stop()
          this.toastr.error("Failed to Create Integration Mapping", "Duplicate Mapping", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        this.searchKeyword = this.mappingmodel.Handler
        this.getxmlmappings();
      })
    }
    else {
      this.ngxUiLoaderService.stop()
      this.toastr.warning(
        "DeploymentKey is Mandatory",
        "",
        {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        }
      );
    }
  }

  updatemappings(lookupobj) {
    this.ngxUiLoaderService.start()
    this.service.updateIntegrationMapping(lookupobj).subscribe(data => {
      if (data.statusmessage != 'failure') {
        this.getxmlmappings();
        this.ngxUiLoaderService.stop()
        this.updatemapping = data;
        this.toastr.success("Integration Mapping Updated Successfully", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        })

      } else {
        this.ngxUiLoaderService.stop()
        this.toastr.error("Failed to Update Integration Mapping", "Duplicate Mapping", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }

    })
  }

  closetpopup() {
    this.closelookup = true;
    this.closeResponse = true;
    this.reactiveFormmapping.reset();
    this.mappingid = 0;
    this.FormFlag = false;
    this.xmlmappinglist.MappingList = [];
    this.currentPageIndex = 1;
  }

  createMapping(AddPopup: any) {
    this.closelookup = true;
    this.closeResponse = true;
    this.reactiveFormmapping.reset();
    this.mappingid = 0;
    this.FormFlag = true;
    this.modalService.open(AddPopup, { windowClass: "modalConfig", backdrop: 'static', keyboard: false });


    this.mappingmodel.Handler = '';
    this.mappingmodel.LookupType = '';
    this.mappingmodel.LookupId = '';
    this.mappingmodel.LookupValue = '';
    this.searchKeyword = '';
    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);

  }

  copyToClips(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  async closepopup(h) {
    this.editdeployment = false;
    await this.clearnotepopupvalues();
    h('close modal');
  }

  async clearnotepopupvalues() {
    this.failedMessageNote = '';
  }


}

export interface mappingdata {
  Handler: string;
  Lookuptype: string;
  Lookupvalue: string;
  Lookupid: string;
  Id: bigint;
}
