import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER, V } from '@angular/cdk/keycodes';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatMenuTrigger } from '@angular/material/menu';

import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {  Subject, Subscription, takeUntil } from 'rxjs';
import { CommonService } from '../services/common.service';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { W700servicesService } from '../services/w700services.service';
import { LocationStrategy } from '@angular/common';
declare var $
declare var window: any;
@Component({ selector: 'app-homepage', templateUrl: './homepage.component.html', styleUrls: ['./homepage.component.scss'] })
export class HomepageComponent implements OnInit {
  private destroy$ = new Subject<void>();
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  public reactiveOrganization: FormGroup;
  public reactiveAccount: FormGroup;
  userActivity;
  userInactive: Subject<any> = new Subject();
  Organizations = <any>[];
  Accounts = <any>[];
  CaseTypes = [];
  keywordsearch_Organization = "OrganizationName";
  keywordsearch_Account = "Account";
  keywordsearch_CaseType = "CaseType";
  keywordsearch_Deployment = "Deployment";
  OrganizationID: number = 0;
  AccountID: number = 0;
  CaseType: string = "";
  dropdownList = [];
  selectedItems = [];
  dropdownSettingsforcasetype = {};
  dropdownSettingsforaoe = {};
  OrderCode: string = "";
  selectedCaseType = [];
  selectedAoeType: any[] = [];
  OptionalAOE: boolean = true;
  Deployment: string = "";
  ctrlAccounts = new FormControl();
  isVendor = "Compendium";
  AOE = "";
  ctrlOrganization: string = "";
  usernamedisplay: any;

  aoelist: any[] = [
    "Jars",
    "Services",
    "Collection Method",
    "Sites",
    "Clinical History",
    "Bill To",
    "Rule Outs"
  ];
  pagerolepermisions: any;
  isLoadingResult: boolean = false;
  isLoadingResultAccount: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  caseTypeCtrl = new FormControl();

  @ViewChild('caseInput', { static: false }) caseInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('myPanel', { static: false }) myPanel: MatExpansionPanel;
  CompendiumAccount: string;
  compendiumaoe: string;
  menus: any;
  SelectedUser = "";
  menusList: any;
  menusArray = [];
  leftmenus: any[];
  browserRefresh;
  previousUrl: any;
  isRefreshed: boolean;
  constructor(
    private location: LocationStrategy,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private LoginService: LoginService,
    private _fb: FormBuilder,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService, private service: CommonService, public Service: W700servicesService) {
    this.reactiveOrganization = this._fb.group({ orgname: '' });
    this.reactiveAccount = this._fb.group({ accname: '' });
    this.userInactive.subscribe(() => {
      clearTimeout(this.userActivity);
      this.ngxUiLoaderService.stopAll();
      window.history.forward()
      this.nobackbutton()
      $('#screenlock').modal({ backdrop: 'static', keyboard: false });
    });

    this.route.queryParams
      .subscribe(async params => {
        const Input = params['Input'];
        if (Input != undefined && Input !== '') {
          sessionStorage.setItem('ssoinput', JSON.stringify(Input))
          await this.getpermission();
        }
      }
      );
  }
  rolemenupermissionlst:Rolepermissionslst[]= [];
  async getpermission() {
      
    const postentity: any = {
      "Encryptvalue": JSON.parse(sessionStorage.getItem("ssoinput")),
    }
    
    var datares = await this.loginService.getrolesdatapost('api/UserRoleConfig/SaveOrGetSSOUser', postentity)
     

      if (datares!=null) {
        sessionStorage.setItem('SelectedUserRole', datares["RoleName"]);

        var sessionObject = {
          userdata: {
            FormattedDisplayName: datares["UserID"],
            userid: datares["UserID"]
          }
        }
        sessionStorage.setItem('sessionObjectlogin', JSON.stringify(sessionObject));

        const newRolePermission: Rolepermissionslst = {
          PrimaryRoleId: datares["PrimaryRoleId"],
          RoleID: datares["RoleID"],
          RoleName: datares["RoleName"],
          UserID: datares["UserID"],
          ftpDetailId: datares["ftpDetailId"],
          menuslist: datares["menuslist"],
          uniqueId: datares["uniqueId"]
        };
        this.rolemenupermissionlst.push(newRolePermission);
        sessionStorage.setItem('rolewithmenus', JSON.stringify(this.rolemenupermissionlst));
      }
 
  }
  servicesso: boolean = false;
  servicessostatussub: Subscription;
  subscription: Subscription;
  subscriptionroutes: Subscription;
  
  verdorlist = [
    'Compendium',
    'Raw',
    'AllScripts',
    'eCW',
    'GMed',
    'Vanadis'
  ];
  bLogin: boolean = true;
  isShow: boolean;
  displaymessage: boolean = false;
  selectedrouteId:string='';
  createsubtab:any;
  msgsubtab:any;
  vendint:any;
  errorMessage: string = "User does not have the required access to proceed further. Kindly reach out to vahubteam@vitalaxis.com Team for assistance."
  ngOnInit() {

    this.servicessostatussub = this.service.servicessostatus.subscribe((data) => {
      if (data) {
        this.servicesso = true;
      } else {
        this.servicesso = false;
      }
    });
    this.createsubtab=this.service.pipelineName$
    .pipe(takeUntil(this.destroy$))
    .subscribe((id:string)=>{
      if(typeof(id)=='string'){
      this.selectedrouteId=id;
      if(this.selectedrouteId!=null){
        this.SelectedMenus('create',false);
    }
  }
    });
    this.msgsubtab=this.service.message$
    .pipe(takeUntil(this.destroy$))
    .subscribe((data:any)=>{
      this.selectedrouteId=data.id;
      if(this.selectedrouteId!=null){
        this.SelectedMenus('messages',false);
      }
    });
    this.vendint=this.service.vendorinterface$
    .subscribe((data:any)=>{
      if(data!=null){
        this.SelectedMenus('interface',false);
      }
    })
    if(this.selectedrouteId==null || this.selectedrouteId=='')
    {
    if (JSON.parse(sessionStorage.getItem('ssoinput')) == '' || JSON.parse(sessionStorage.getItem('ssoinput')) == null && sessionStorage.length > 0) {
      this.ngxUiLoaderService.start();

      var UserName = '';
      this.LoginService.getUserInfo().subscribe({
        next: (data) => {
          if (data == null) {

            this.ngxUiLoaderService.stopAll();
            this.router.navigate(['/login']);
          }
          else {
            UserName = data.email;
            if (UserName.includes('starmarkit.com')) {
              UserName = UserName.replace('starmarkit.com', 'starmark.com')
            }

            var sessionObject1 = {
              userdata: {
                FormattedDisplayName: UserName,
                userid: UserName
              }
            }
            sessionStorage.setItem('sessionObjectlogin', JSON.stringify(sessionObject1));
            this.isRefreshed = history.state.navigationId == 1 ? true : false;
            var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
            if (sessionObject != null) {
              this.loginService.getrolesdata('api/UserRoleConfig/GetUserRoleConfig?UserId=' + sessionObject.userdata['FormattedDisplayName']).subscribe(data => {
                if (data ? data.length > 0 : false) {
                  this.displaymessage = false;
                  sessionStorage.setItem('rolewithmenus', JSON.stringify(data))
                  const rolename = data.find(x => x.PrimaryRoleId == true).RoleName;
                  sessionStorage.setItem('SelectedUserRole', rolename);
                  let defaultmenu = data.find(x => x.RoleName == rolename)
                  const defaultmenuobj = defaultmenu.menuslist.find(z => z.IsDefaultMenu == true) ? defaultmenu.menuslist.find(z => z.IsDefaultMenu == true) : '';
                  if (defaultmenuobj.MenuName == '' || defaultmenuobj.MenuName == undefined) {
                    // this.router.navigate(['/home/tryitout']);
                    this.SelectedMenus('try it out',false);
                  } else {
                    var urls = '/home/' + defaultmenuobj.MenuName;
                    // if (!this.isRefreshed || JSON.parse(sessionStorage.getItem("selectedRole")) == null || this.router.url == '/home') {
                    //   this.router.navigate([urls]);
                    // }
                    this.refresh();
                    this.SelectedMenus(defaultmenuobj.MenuDisplayName,false);
                  }
                  
                  this.ngxUiLoaderService.stopAll()
                } else {
                  this.displaymessage = true;
                  this.ngxUiLoaderService.stopAll()
                }
              }, erro => {
                this.toastr.error(erro.statusText, "Failed", {
                  timeOut: 4000,
                  positionClass: 'toast-bottom-right'
                });
                this.ngxUiLoaderService.stopAll();
              })
            }
            else {
              this.ngxUiLoaderService.stopAll()
              this.router.navigate(['/login']);
            }
          }
        },
        error: error => {

          this.ngxUiLoaderService.stopAll();
          this.router.navigate(['/login']);
        }
      });
      //this.ngxUiLoaderService.stop();
    }
  }
  this.selectedrouteId=null
  }
  refresh() {
    this.subscription = this.service.currentMessage.subscribe(message => {
      if (message) {
        this.ngxUiLoaderService.stopAll();
        this.lockscreen();
      } else {
        $('#screenlock').modal('hide');
      }
    });

    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    if (sessionObject != null) {

      let name = sessionObject.userdata['FormattedDisplayName']
      this.usernamedisplay = name.split('@')[0].replaceAll('.', ' ')

      this.menusList = JSON.parse(sessionStorage.getItem('rolewithmenus'));
      if (this.menusList.length > 0) {
        let isbackuproles = JSON.parse(JSON.stringify(this.menusList))
        for (var i = 0; i < isbackuproles.length; i++) {
          let ar = this.uniqmenuarray(isbackuproles[i].menuslist)
          if (ar.length > 0) {
            isbackuproles[i].menuslist = ar
          }
        }
        this.menusArray = isbackuproles
        if (this.menusArray.length > 0) {
          if (sessionStorage.getItem('SelectedUserRole') == (this.menusArray.find(x => x.PrimaryRoleId == true).RoleName) && JSON.parse(sessionStorage.getItem("selectedRole")) == null) {
            this.SelectRole(this.menusArray.find(x => x.PrimaryRoleId == true), false);
          }
          else {
            this.SelectRole(JSON.parse(sessionStorage.getItem("selectedRole")), false);
          }
        }
      }
    }
  }
  collapseMenu() {
    this.myPanel.accordion.closeAll();
    this.myPanel.expanded = true

  }
  SelectRole(value, isflag) {
    this.SelectedUser = value.RoleName;
    sessionStorage.setItem('SelectedUserRole', this.SelectedUser);
    sessionStorage.setItem('selectedRole', JSON.stringify(value))
    this.leftmenus = []
    let uniqrole = this.menusArray.filter(va => va.RoleName === value.RoleName)
    if (uniqrole.length > 0) {
      this.leftmenus = uniqrole[0].menuslist
      if (this.leftmenus.length > 0) {
        this.leftmenus = this.sortByKey(this.leftmenus, "MenuOrder")
        let defaultsroute = this.leftmenus.filter(va => va.IsDefaultMenu == true)
        if (defaultsroute.length == 0) {
          for (let i = 0; i < this.leftmenus.length; i++) {
            if (this.leftmenus[i].submenu) {
              defaultsroute = this.leftmenus[i].submenu.filter(va => va.IsDefaultMenu == true);
              if (defaultsroute.length > 0) {
                break;
              }
            }
          }
        }
        if (!this.isRefreshed || isflag) {
          this.isRefreshed = false;
          if (defaultsroute.length > 0) { this.router.navigate(["home/" + defaultsroute[0].MenuName]) }
          else {
            this.toastr.warning('Selected Role default menu mapping unavailable', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });

            setTimeout(() => {
              this.loginService.logout();
            }, 3000);

          }
          // if (this.router.url.split('/')[2] == defaultsroute[0].MenuName) {
          //   // window.location.reload(true);
          //   // this.refresh();
          // }
        }
      }
    }
  }



  uniqmenuarray(array) {
    if (array !== undefined) {
      let main = []
      if (array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          main = array.filter(va => va.MenuType == "Main")
          if (main.length > 0) {
            for (let i = 0; i < main.length; i++) {
              let arry = array.filter(va => va.ParentMenuId === main[i].MenuId && va.MenuType == "SubMain")
              if (arry.length > 0) {
                main[i]['submenu'] = arry
                for (let j = 0; j < arry.length; j++) {
                  let ind = array.findIndex(va => va.MenuName === arry[j].MenuName)
                  array.splice(ind, 1);
                }
              }
            }
            return main
          }
        }
      }
    }
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }


  nobackbutton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    })
  }
  ngOnDestroy() {
    this.destroy$.next();
    if (this.subscription != undefined && this.userInactive != undefined) {
      this.subscription.unsubscribe();
      this.userInactive.unsubscribe();
    }

    // clearTimeout(this.userActivity);
  }

  logout() {

    this.LoginService.logout();
    this.service.changeMessage(false);

  }

  // setTimeout() {
  //   this.userActivity = setTimeout(() => this.userInactive.next(undefined), environment.sessionTimeout);
  // }


  classw700: string = '';
  classw700flag: boolean = false;


  screenHeight = window.innerHeight;
  lockscreen() {
    this.nobackbutton()

    $('#screenlock').modal({ backdrop: 'static', keyboard: false });
    setTimeout(() => { // this.lockpassword.nativeElement.select();
    }, 0);
  }

  UnlockUser() {

    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));

    let loginval = {
      UserName: sessionObject.userdata['userid']
    }
    if (loginval.UserName != '') {
      this.ngxUiLoaderService.start();
      this.LoginService.RefreshToken().subscribe({
        next: async data => {
          $('#screenlock').modal('hide');
          this.ngxUiLoaderService.stopAll()
        },
        error: error => {
          this.ngxUiLoaderService.stopAll()
          // this.toastr.error('Login Token is expired' , "", {
          //   timeOut: 4000,
          //   positionClass: 'toast-bottom-right'
          // });
          // this.LoginService.Login(loginval.UserName);
        }
      });
    }
  }

  loadPrerequisites() {
    this.dropdownSettingsforcasetype = {
      singleSelection: false,
      idField: 'CaseType',
      textField: 'CaseTypeDisplayName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    };

    this.dropdownSettingsforaoe = {
      singleSelection: false,
      idField: 'AoeDetails',
      textField: 'AoeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    }


  }
  openMyMenu() {
    this.trigger.toggleMenu();
  }
  closeMyMenu() {
    this.trigger.closeMenu();
  }
  onCaseTypeSelect(item: any) {
    this.selectedCaseType.push(item.CaseType);
  }
  onCaseTypeSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.selectedCaseType.push(items[i].CaseType);
    }

  }
  onaoeSelect(item: any) {
    this.selectedAoeType.push(item.AoeDetails);
  }
  onaoeSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.selectedAoeType.push(items[i].AoeDetails);
    }

  }

  onCaseTypeDeSelect(item: any) {
    this.selectedCaseType = this.selectedCaseType.filter(x => x != item)
  }
  onaoeDeSelect(item: any) {
    this.selectedAoeType = this.selectedAoeType.filter(x => x != item)
  }
  onCaseTypeDeSelectAll(items: any) {
    this.selectedCaseType = [];
  }
  onaoeDeSelectAll(items: any) {
    this.selectedAoeType = [];
  }

  Compendium: ICompendium | undefined;

  async getOrganization(input: any) {
    this.Accounts = [];
    this.CaseTypes = [];
    if (input.length < 3) {
      return;
    }
 

    if (this.Validate() == false) {
      return;
    }
    var urlterm = "api/Compendium/GetOrganizations?OrganizationName=" + input;
    this.isLoadingResult = true;
    // this.Accounts=await this.Service.postapi("api/Compendium/GetAccounts" ,OrganizationDetails, this.Deployment.toLowerCase());
    this.Service.getapi(urlterm, this.Deployment.toLowerCase()).subscribe(data => {
      if (data) {
        this.Organizations = data as any[];
      }
    })
    this.isLoadingResult = false;
  }

  getOrganizationName(input: any) { }

  async getAccounts(input: any) {

    this.OrganizationID = input.OrganizationID;
    this.AccountID = 0;
    this.CaseType = "";


    this.CaseTypes = [];
    if (this.Validate() == false) {
      return;
    }
    var urlterm = "api/Compendium/GetAccounts?OrganizationID=" + this.OrganizationID;
    this.isLoadingResultAccount = true;
    // this.Accounts=await this.Service.postapi("api/Compendium/GetAccounts" ,OrganizationDetails, this.Deployment.toLowerCase());
    this.Service.getapi(urlterm, this.Deployment.toLowerCase()).subscribe(data => {
      if (data) {
        this.Accounts = data as any[];
      }
    })
    this.isLoadingResultAccount = false;
  }

  async getCaseTypes(input: any) {
    this.AccountID = input.value;

    var urlterm = "api/Compendium/GetCaseTypes?LabOrgID=" + this.OrganizationID + "&LabAccountID=" + this.AccountID;

    if (this.Validate() == false) {
      return;
    }
    this.Service.getapi(urlterm, this.Deployment.toLowerCase()).subscribe(data => {
      if (data) {
        let obj = {
          CaseType: "All",
          OrderCode: "All",
          CaseTypeDisplayName: "All"
        }
        data.length > 0 ? data.unshift(obj) : data;
        this.CaseTypes = data as any[];
      }
    })
  }

  searchOrganizationCleared(input: any) {
    this.Accounts = [];
    this.CaseTypes = [];
  }

  searchAccountsCleared(input: any) {
    this.CaseTypes = [];
  }

  GetOptionalAOE(input: any) {
    this.OptionalAOE = input.value;
  }

  Validate() {
    if (this.Deployment == "" || this.Deployment == 'Deployment') {
      this.toastr.warning('Choose a deployment ', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    return true;
  }

  GetCompendium() {
    let caseTypesValue = "";
    if (this.selectedCaseType.length > 0) {
      let exists = this.selectedCaseType.filter(va => va === 'All');
      if (exists.length > 0 && this.CaseTypes.length > 0) {
        for (let i = 0; i < this.CaseTypes.length; i++) {
          if (this.CaseTypes[i].CaseType !== 'All' && i > 0 && i < this.CaseTypes.length - 1) {
            caseTypesValue += this.CaseTypes[i].CaseType + ",";
          } else if (this.CaseTypes[i].CaseType !== 'All') {
            caseTypesValue += this.CaseTypes[i].CaseType;
          }
        }
      } else {
        caseTypesValue = this.selectedCaseType.join(',')
      }
    }


    this.Compendium = {
      OrganizationID: this.OrganizationID,
      AccountID: this.AccountID,
      CaseType: caseTypesValue,
      OptionalAOE: this.OptionalAOE,
      OrganizationName: "",
      Vendor: this.isVendor,
      AOEs: this.selectedAoeType.join(',')
    }

    if (this.Validate() == false) {
      return;
    }
    var urlterm = "api/Compendium/GetCompendium?OrganizationID=" + this.OrganizationID + "&AccountID=" + this.AccountID + "&CaseType=" + caseTypesValue + "&OptionalAOE=" + this.OptionalAOE + "&Vendor=" + this.isVendor;

    if (this.Validate() == false) {
      return;
    }
    this.ngxUiLoaderService.start();
    this.Service.getCompendiumExcel(urlterm, this.Deployment.toLowerCase()).subscribe((file) => {
      if (file) {
        const source = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file.body['FileContents']
          }`;
        const link = document.createElement("a");
        link.href = source;
        link.download = "Compendium_" + Date.now().toString() + ".xlsx";
        link.click();
        this.ngxUiLoaderService.stop();
        this.toastr.success('Compedium downloaded successfully', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        $('#exampleModal').modal('hide');

      }

    })
  }

  OnDeploymentChange(input: any) {
    if (this.Deployment == input.value) {
      return;
    }
    this.ctrlAccounts.setValue('');
    this.Deployment = input.value;
    this.Organizations = [];
    this.Accounts = [];
    this.CaseTypes = [];
  }
  OnisVendorChange(input: any) {
    this.isVendor = input.target.value;
  }

  OnAOEChange(input: any) {
    this.AOE = input.target.value;
  }

  getDeployment(input: any) { }

  openCompendium() {
    this.clear();
    $('#exampleModal').appendTo("body").modal('show', {
      backdrop: 'static',
      keyboard: false
    });
  }
  clear() {
    this.Deployment = '';
    this.CaseTypes = [];
    this.selectedCaseType = [];
    this.CompendiumAccount = "";
    this.compendiumaoe = "";
    this.caseTypeCtrl.setValue('')
    this.Accounts = [];
    this.Organizations = [];
    this.isVendor = "";
    this.reactiveOrganization.reset();
    this.reactiveAccount.reset();
  }


  remove(casetype: string): void {
    const index = this.selectedCaseType.indexOf(casetype);
    if (index >= 0) {
      this.selectedCaseType.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    if (event.option.viewValue == 'All') {
      this.selectedCaseType = []
    }
    if (this.selectedCaseType.length > 0) {
      let exists = this.selectedCaseType.filter(va => va === 'All');
      if (exists.length > 0) {
        this.toastr.warning(exists[0] + " " + "option includes every casetype for the lab account", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.caseInput.nativeElement.value = '';
        this.caseTypeCtrl.setValue(null);
        this.caseInput.nativeElement.blur();
      } else {
        let exist = this.selectedCaseType.filter(va => va === event.option.viewValue);
        if (exist.length > 0) {
          this.toastr.warning(exist[0] + " " + 'Already Exist', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        } else {
          this.selectedCaseType.push(event.option.viewValue);
          this.caseInput.nativeElement.value = '';
          this.caseTypeCtrl.setValue(null);
          this.caseInput.nativeElement.blur();
        }
      }
    } else {
      this.selectedCaseType.push(event.option.viewValue);
      this.caseInput.nativeElement.value = '';
      this.caseTypeCtrl.setValue(null);
      this.caseInput.nativeElement.blur();
    }
  }
  selectedComponents:any[]=[];
  indexnum:number=0
  SelectedMenus(menuname:string,ishtml:boolean){
  let ispresent =this.selectedComponents.length>0?this.selectedComponents.find(V=>V.displayname.toLowerCase() ===menuname.toLowerCase()):[];
  if(ispresent==undefined || ispresent.length==0){
    let obj={
      id:this.indexnum,
      displayname:menuname
    };
    this.selectedComponents.push(obj);
    this.getComponent(menuname);
    ishtml?this.selectedTabIndex=this.selectedComponents.length-1:this.selectedTabIndex=this.indexnum;
    this.indexnum++;
  }
  else{
    this.selectedTabIndex=ispresent.id;
    if(ishtml){
    this.toastr.warning('Tab Already Exist', "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
  }
  }
  }
  getComponent(Menuname: string): any {
   let component= this.service.Getcomponentbyname(Menuname);
   if(component!=''&&component!=undefined&&component!=null){
    return component
   }
   else{
    this.toastr.warning('Error Occured', "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
    return '';
   }
  }
  selectedTabIndex: number = 0;
  closeTab(index: number) {
    let selectedcomp=this.selectedComponents.find(V=>V.id===index);
    this.indexnum--;
    this.selectedComponents.splice(index, 1);
    if (this.selectedTabIndex >= this.selectedComponents.length) {
      this.selectedTabIndex = this.selectedComponents.length - 1;
    }
    this.selectedComponents.forEach((element,i) => {
      element.id=i;
    });
    this.service.stopEmitting(selectedcomp.displayname);
  }

}

export interface ICompendium {
  OrganizationID: Number,
  CaseType: String,
  AccountID: Number,
  OptionalAOE: boolean,
  Vendor: String,
  OrganizationName: String,
  AOEs: String
}
export interface Rolepermissionslst {
  PrimaryRoleId: any;
  RoleID: any;
  RoleName:any;
  UserID:any;
  ftpDetailId:string;
  menuslist:any;
  uniqueId:any;
}