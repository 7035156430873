import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-tracelog',
  templateUrl: './tracelog.component.html',
  styleUrls: ['./tracelog.component.scss']
})
export class TracelogComponent implements OnInit {
  vatracedata: any = [];
  vatraceflag: boolean = false;
  today = new Date();
  totalmessagecount: any;

  constructor(private _fb: FormBuilder, private service: CommonService, private toastr: ToastrService, private ngxUiLoaderService: NgxUiLoaderService) {
  }
  reactiveFormtrasactionid: FormGroup = this._fb.group({
    traceid: ['', Validators.required],
    agentid: ['', Validators.required],
    fromdate: ['', Validators.required],
    todate: ['', Validators.required],
    messageid: ['', Validators.required],
    contenttext: ['', Validators.required],
    pagenumber:  1,
    PageSize : 100
  });

  ngOnInit() {
    this.reactiveFormtrasactionid = this._fb.group({
      traceid: ['', Validators.required],
      agentid: ['', Validators.required],
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      messageid: ['', Validators.required],
      contenttext: ['', Validators.required],
      pagenumber:  1,
      PageSize : 100
    });

    const yesterday = new Date(this.today);
    yesterday.setDate(this.today.getDate() - 1);

    this.reactiveFormtrasactionid.patchValue({
      fromdate: yesterday.toISOString().substring(0, 10), // YYYY-MM-DD format
      todate: this.today.toISOString().substring(0, 10)  // YYYY-MM-DD format
    });
  }


  SearchTraceLog() {
    if (this.ValidateVatrceInputdata(this.reactiveFormtrasactionid.value)) {
      const d1 = new Date(this.reactiveFormtrasactionid.value.fromdate);
      const d2 = new Date(this.reactiveFormtrasactionid.value.todate);
      const timeDiff = Math.abs(d1.getTime() - d2.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays >= 2) {
        this.toastr.warning("Date range should not exceed 2 days", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      }
      else {
        this.ngxUiLoaderService.start();
        this.service.GetVatraceLogs(this.reactiveFormtrasactionid.value).subscribe((response: any) => {
          if (response.length > 0) {
            this.vatracedata = response;
            this.vatraceflag = true;
            this.ngxUiLoaderService.stop();
            this.totalmessagecount = response[0].totalmessagecount
          }
          else {
            this.vatraceflag = false;
            this.vatracedata = [];
            this.ngxUiLoaderService.stop();
            this.toastr.warning("VaTraceLogs Not Found!", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          }
        },
          error => {
            this.vatraceflag = false;
            this.vatracedata = [];
            this.ngxUiLoaderService.stop();
            this.toastr.error("error occurred to get VaTraceLogs!", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          });
      }
    }
    else {
      this.vatraceflag = false;
      this.vatracedata = [];
      this.toastr.warning("Please enter the required fields", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }

  }

  ValidateVatrceInputdata(vatraceinputdata) {
    if (vatraceinputdata.fromdate != null &&
      vatraceinputdata.fromdate != "" &&
      vatraceinputdata.todate != null &&
      vatraceinputdata.todate != ''
    ) {
      return true;
    } else {
      return false;
    }
  }
  ResetFormsData() {
    this.reactiveFormtrasactionid.reset();
    const yesterday = new Date(this.today);
    yesterday.setDate(this.today.getDate() - 1);
    this.reactiveFormtrasactionid.patchValue({
      fromdate: yesterday.toISOString().substring(0, 10), // YYYY-MM-DD format
      todate: this.today.toISOString().substring(0, 10) , // YYYY-MM-DD format
      pagenumber:  1,
      PageSize : 100
    });
    this.vatracedata = [];
    this.vatraceflag = false;
  }
  closelogpopup(c) {
    this.vatracedata = [];
    this.vatraceflag = false;
  }
  downloadtracelog() {
   this.ExportInterfacelist();
  }

  ExportInterfacelist() {
    let filename = "data.xlsx";
    const data: any[] = [];
    if (this.vatracedata.length != 0) {
      this.vatracedata.forEach((element: {
        agentid: any; messageid: any; method: any; routedefinitionid: any; messagetype: any; senderagentid: any; sourceentityid: any;
        destinationentityid: any; destinationagentid: any; response: any; createddate: any; fileid: any; invokedurl: any;
        stage: any; hash: any; processingparam: any; status: any; processingtime: any; traceid: any; exception: any;
      }) => {
        data.push({
          traceid: element.traceid,
          agentid: element.agentid,
          messageid: element.messageid,
          method: element.method,
          routedefinitionid: element.routedefinitionid,
          messagetype: element.messagetype,
          Stage: element.stage,
          Status: element.status,
          senderagentid: element.senderagentid,
          sourceentityid: element.sourceentityid,
          destinationentityid: element.destinationentityid,
          destinationagentid: element.destinationagentid,
          response: element.response,
          createddate: element.createddate,
          invokedurl: element.invokedurl,
          fileid: element.fileid,
          hash: element.hash,
          processingparam: element.processingparam,
          processingtime: element.processingtime,
          exception: element.exception
        });

      });
    }
    if (data.length > 0) {
      this.exportToExcel(data, filename);
    } else {
      this.toastr.warning("There is no data !", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  exportToExcel(items: any[], fileName: string): void {
    const selectedItems = items.map(item => {
      return {
        "traceid": item.traceid,
        "agentid": item.agentid,
        "messageid": item.messageid,
        "method": item.method,
        "routedefinitionid": item.routedefinitionid,
        "Stage": item.Stage,
        "Status": item.Status,
        "messagetype": item.messagetype,
        "senderagentid": item.senderagentid,
        "sourceentityid": item.sourceentityid,
        "destinationentityid": item.destinationentityid,
        "destinationagentid": item.destinationagentid,
        "response ": item.response,
        "createddate": item.createddate,
        "invokedurl": item.invokedurl,
        "fileid": item.fileid,
        "hash": item.hash,
        "processingparam": item.processingparam,
        "processingtime": item.processingtime,
        "exception": item.exception
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  getPageDetails(event: { pageIndex: number; pageSize: number }) {
    if (event.pageIndex == 0) {
      this.reactiveFormtrasactionid.value.pagenumber = 1;
    } else {
      this.reactiveFormtrasactionid.value.pagenumber = event.pageIndex + 1;
    }
    this.reactiveFormtrasactionid.value.PageSize = 100;
    this.SearchTraceLog();
  }

}
