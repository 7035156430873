import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewauditComponent } from '../newaudit/newaudit.component';
import { PageEvent } from '@angular/material/paginator';
declare const copyToClipboard: any;

interface LookupFreq {
  displayname: string
}

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})

export class JobComponent implements OnInit {

  jobList: any[];
  jobLogList: any[];
  jobuniqueid: any;
  agentList: any[];
  logjobid: string;
  logjobname: string;
  selectedagentid: string = '';
  isLoadingagent: boolean = false;
  isCreate: boolean = false;
  isEdit: boolean = false;
  keyword = "name";
  searchkeyword = "displayname"
  jobForm: FormGroup;
  searchJobForm: FormGroup;
  frequencyList: LookupFreq[] = [];
  runstatusList: LookupFreq[] = [];
  cardHeight: Number = window.innerHeight - 170;
  logmodalheight: Number = window.innerHeight - 100;
  editJobobj: any = {};
  itemsPerPage: number = 100;
  currentPage: number = 1;
  totalJobLogsCount: any[];

  displayedLogs: any[] = [];
  public status = [
    { name: 'Active', value: 'active' },
    { name: 'Passive', value: 'passive' }
  ];

  public jobtypes = [
    { name: 'Sync', value: 'sync' },
    { name: 'Async', value: 'async' }
  ];

  constructor(private jobGroup: FormBuilder, private searchJobGroup: FormBuilder, private ngxUiLoaderService: NgxUiLoaderService, private service: CommonService, private toastr: ToastrService, private modalService: NgbModal) {
    this.jobForm = this.jobGroup.group({
      jobname: ['', Validators.required],
      agent: ['', Validators.required],
      frequencydisplayname: ['', Validators.required],
      jobtype: ['', Validators.required],
      content: ['', Validators.required],
      joburl: ['', Validators.required],
      status: ['', Validators.required],
      runstatus: ['', Validators.required],
      lastrundata: [''],
      modifiedby: [''],
      frequency: [''],
      lastjobtrigger: [''],
      freqinminute: [''],
      lastrun: [''],
      processingparam: ['', Validators.required]
    });
    this.searchJobForm = this.searchJobGroup.group({
      searchagent: [''],
      searchjobid: [''],
      searchjobname: [''],
      searchfrequency: [''],
      searchstatus: ['']
    });
  }

  ngOnInit() {
    this.getJobFrequencies();
    this.getJobRunStatus();
    this.getAllJobs();
    this.isCreate = false;
  }

  getAgentNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingagent = true;
      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else {
            this.agentList = data as any[];
            this.agentList = this.service.transformAlphabetically(this.agentList, 'displayname');
            this.agentList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'} ${'('} ${obj.apikey} ${')'}`);
            this.isLoadingagent = false;
            if (data.length == 0) {
              // this.notfounddata = true;
            }
            else {
              // this.notfounddata = false;
            }
          }
        },
        error: error => {
          this.isLoadingagent = false;
          //  this.notfounddata = true;
        }
      });
    }
  }

  getJobFrequencies() {
    var apiname = "api/Lookup/GetLookUpId?lookuptype=jobtrigger";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        data.forEach(element => {
          this.frequencyList.push({ displayname: element });
        });
      }
    });
  }

  getJobRunStatus() {
    var apiname = "api/Lookup/GetLookUpId?lookuptype=jobrunstatus";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        data.forEach(element => {
          this.runstatusList.push({ displayname: element });
        });
      }
    });
  }

  addoreditjob(modal, isupdate, obj) {
    this.jobForm.reset();
    this.jobForm.patchValue({   // patching empty fields to get list
      frequencydisplayname: '',
      jobtype: '',
      status: '',
      runstatus: '',
    })
    this.editJobobj = {};
    if (isupdate) {
      this.editJobobj = obj;
      this.isEdit = true;
      this.jobForm.patchValue({
        jobname: obj.displayname,
        agent: obj.agentid,
        frequencydisplayname: obj.frequencydisplayname,
        jobtype: obj.jobtype,
        status: obj.status,
        runstatus: obj.runstatus,
        joburl: obj.joburl,
        content: obj.content,
        processingparam: obj.processingparam,
        lastrundata: obj.lastrundata,
        jobid: obj.jobid,
        modifiedby: obj.modifierid,
        frequency: obj.frequency,
        lastjobtrigger: obj.lastjobtrigger,
        freqinminute: obj.frequencyinmins,
        lastrun: obj.lastrun
      })
    }
    else {
      this.isEdit = false;
    }
    this.modalService.open(modal, { windowClass: "jobmodal", backdrop: 'static', keyboard: false }).result.then((result) => { });

  }

  closeCreate(c) {
    this.isCreate = false;
    this.jobForm.reset();
    c('close modal');
  }

  getAllJobs() {
    this.jobList = [];
    this.ngxUiLoaderService.start();
    let job = {
      agentid: '',
      jobid: '',
      displayname: '',
      frequencydisplayname: '',
      status: ''
    };
    this.service.getJobSearch(job).subscribe(data => {
      if (data) {
        if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          this.jobList = data as any[];
        }
      }

    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error(error.statusText, "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
  }

  searchJob(value) {
    this.jobList = [];
    this.ngxUiLoaderService.start();
    let job = {
      agentid: (value.searchagent === null) ? '' : (value.searchagent.id === undefined ? value.searchagent : value.searchagent.id),
      jobid: (value.searchjobid === null) ? '' : (value.searchjobid),
      displayname: (value.searchjobname === null) ? '' : (value.searchjobname),
      frequencydisplayname: (value.searchfrequency === null) ? '' : (value.searchfrequency.displayname === undefined ? value.searchfrequency : value.searchfrequency.displayname),
      status: (value.searchstatus === null) ? '' : (value.searchstatus.value === undefined ? value.searchstatus : value.searchstatus.value)
    };
    this.service.getJobSearch(job).subscribe(data => {
      if (data) {
        if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          this.jobList = data as any[];
          if (this.jobList.length === 0) {
            this.toastr.warning("No results found!!", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        }
      }

    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error(error.statusText, "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
  }



  saveJob(value, editJob, c) {
    let jobData = {};
    this.ngxUiLoaderService.start();

    if (value.content.trim() != '') {
      if (this.service.IsJsonString(value.content.trim()) && this.service.IsJsonString(value.processingparam.trim())) {
        if (editJob === '') {
          jobData = {
            uniqueid: 0,
            jobid: '',
            agentid: value.agent.id,
            jobtype: value.jobtype.value,
            content: value.content,
            processingparam: value.processingparam,
            joburl: value.joburl,
            runstatus: value.runstatus.displayname,
            frequencydisplayname: value.frequencydisplayname.displayname,
            displayname: value.jobname,
            status: value.status.value,
            monitoring: '',
            modifierid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
          }
        }
        else {
          jobData = {
            uniqueid: editJob.uniqueid,
            jobid: editJob.jobid,
            agentid: value.agent == null ? '' : value.agent,
            agentname: editJob.agentname,
            createdby: editJob.createdby,
            createddate: editJob.createddate,
            frequency: editJob.frequency,
            frequencyinmins: editJob.frequencyinmins,
            lastjobtrigger: editJob.lastjobtrigger,
            jobversion: editJob.jobversion,
            lastrun: editJob.lastrun,
            lastrundata: editJob.lastrundata,
            modifieddate: editJob.modifieddate,
            modifierdescription: editJob.modifierdescription,
            userid: editJob.userid,
            jobtype: (editJob.jobtype === value.jobtype) ? value.jobtype : value.jobtype.value,
            content: value.content,
            processingparam: value.processingparam,
            joburl: value.joburl,
            runstatus: (editJob.runstatus === value.runstatus) ? value.runstatus : value.runstatus.displayname,
            frequencydisplayname: (editJob.frequencydisplayname === value.frequencydisplayname) ? value.frequencydisplayname : value.frequencydisplayname.displayname,
            displayname: value.jobname,
            status: (editJob.status === value.status) ? value.status : value.status.value,
            monitoring: '',
            modifierid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
          }
        }
        this.service.createJob(jobData).subscribe((data) => {
          if (data) {
            if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
              this.ngxUiLoaderService.stop();
              this.toastr.error("Something Went Wrong!", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
            }
            else {
              this.ngxUiLoaderService.stop();
              let displaymessage = this.isEdit ? "Job Updated Successfully" : "Job Created Successfully";
              this.toastr.success(displaymessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.closeCreate(c);
              this.reset();
              this.getAllJobs();
            }
          }


        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(error.statusText, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        })
      }
      else {
        this.ngxUiLoaderService.stop();
        let errormessage = this.isEdit ? "Failed to Updated Job; Job's content and processing param should be in json fomat" : "Failed to Create Job; Job's content and processing param should be in json fomat";
        this.toastr.error(errormessage, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }

    }
  }

  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  selectedAgent(data) {
    this.selectedagentid = data.id;
  }

  // showJobLogs(joblogs, job) {
  //   this.logjobid = '';
  //   this.logjobname = '';
  //   this.jobLogList = [];
  //   this.logjobid = job.jobid;
  //   this.logjobname = job.displayname;
  //   this.ngxUiLoaderService.start();
  //   this.service.getJobLogs(job.jobid,this.currentPage, this.itemsPerPage).subscribe(data => {
  //     this.jobLogList = data as any;
  //     this.displayedLogs = this.jobLogList;
  //     if (this.jobLogList.length > 1) {
  //     this.initializePagination();
  //       this.modalService.open(joblogs, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
  //       this.ngxUiLoaderService.stop();
  //     }
  //     else {
  //       this.ngxUiLoaderService.stop();
  //       this.toastr.error("Logs Not Found", "", {
  //         timeOut: 4000,
  //         positionClass: 'toast-bottom-right'
  //       });

  //     }
  //   },
  //     error => {
  //       this.ngxUiLoaderService.stop();
  //       this.toastr.error("Failed to get job details", "", {
  //         timeOut: 4000,
  //         positionClass: "toast-bottom-right",
  //       });
  //     }
  //   );
  // }

  showJobLogs(joblogs, job) {
    this.logjobid = '';
    this.logjobname = '';
    this.jobLogList = [];
    this.logjobid = job.jobid;
    this.logjobname = job.displayname;
    this.ngxUiLoaderService.start();

    this.loadJobLogs(true, joblogs);
  }

  loadJobLogs(openModal: boolean, joblogs) {
    this.ngxUiLoaderService.start();

    this.service.getJobLogs(this.logjobid, this.currentPage, this.itemsPerPage).subscribe({
      next: (data) => {
        this.jobLogList = data;
        this.displayedLogs = this.jobLogList;
        this.totalJobLogsCount = this.jobLogList?.[0]?.TotalCount || 0;

        if (this.jobLogList.length > 1) {
          if (openModal) {
            this.modalService.open(joblogs, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
          }
          this.ngxUiLoaderService.stop();
        }
        else {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Logs Not Found", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        }
      },
      error: (error) => {
        this.toastr.error("Failed to get Job Details", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      },
      complete: () => {
        this.ngxUiLoaderService.stop();
      },
    });
  }

  closeJobLog(c) {
    c('close modal');
  }

  openAuditPopUp(uniqueid, id, displayname) {
    if (uniqueid != '' && id != '') {
      const auditDataToModal = {
        id: uniqueid,
        title: displayname,
        context: "Job",
        type: "ID",
        guid: id,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }

  reset() {
    this.searchJobForm.reset();
    this.searchJobForm.patchValue({   // patching empty fields to get list
      searchfrequency: '',
      searchstatus: ''
    });
    this.jobList = [];
    this.agentList = [];
    this.jobLogList = [];
    this.editJobobj = {};
    this.logjobid = '';
    this.logjobname = '';
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;
    this.loadJobLogs(false, '');
  }
}
