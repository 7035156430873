import { Component, Input, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-caseaudit",
  templateUrl: "./caseaudit.component.html",
  styleUrls: ["./caseaudit.component.scss"],
})
export class CaseauditComponent implements OnInit {
  @Input() caseData: any;
  showAudit: boolean;
  auditListData = [];
  auditData: any = [];
  sequenceList: any = [];
  sequenceListData: any = [];
  Addtion_Details: string = "";
  Action_Details: string = "";
  auditDetials: any;
  parentTablist = [];
  sequenceListBackup: any[];
  selectedTab = {};
  ParentsequenceList: any = [
    {
      tabid: 1,
      sequenceOrder: 11,
      connectivityLabel: "Remote Agent",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 1,
      sequenceOrder: 12,
      connectivityLabel: "VitalAxis-FTP",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 1,
      sequenceOrder: 13,
      connectivityLabel: "VitalBridge-FWU",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 1,
      sequenceOrder: 14,
      connectivityLabel: "VitalHUB",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 1,
      sequenceOrder: 15,
      connectivityLabel: "VitalDX",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 2,
      sequenceOrder: 21,
      connectivityLabel: "Accession",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 2,
      sequenceOrder: 22,
      connectivityLabel: "Site Information",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 2,
      sequenceOrder: 23,
      connectivityLabel: "Grossing",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 2,
      sequenceOrder: 24,
      connectivityLabel: "Processing",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 3,
      sequenceOrder: 31,
      connectivityLabel: "Assignment-Pending Test",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 3,
      sequenceOrder: 32,
      connectivityLabel: "Assigned to",
      connectivityDuration: "32min",
    },
    {
      tabid: 3,
      sequenceOrder: 33,
      connectivityLabel: "Re-assigned to",
      connectivityDuration: "32min",
    },
    {
      tabid: 3,
      sequenceOrder: 34,
      connectivityLabel: "Demographic Verification",
      connectivityDuration: "32min",
    },
    {
      tabid: 4,
      sequenceOrder: 41,
      connectivityLabel: "Procedures",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 4,
      sequenceOrder: 42,
      connectivityLabel: "Diagnosis",
      connectivityDuration: "32min",
    },
    {
      tabid: 4,
      sequenceOrder: 43,
      connectivityLabel: "Preliminary  Report",
      connectivityDuration: "32min",
    },

    {
      tabid: 5,
      sequenceOrder: 51,
      connectivityLabel: "Final Report",
      connectivityDuration: "2h:32min",
    },
    {
      tabid: 5,
      sequenceOrder: 52,
      connectivityLabel: "Distribution",
      connectivityDuration: "32min",
    },
    {
      tabid: 5,
      sequenceOrder: 53,
      connectivityLabel: "Print",
      connectivityDuration: "32min",
    },
  ];

  parentTablistBackup = [
    {
      tablabel: "Requisition",
      tabStatus: "active",
      tabid: 1,
      tabduration: "2h:32min",
    },
    {
      tablabel: "TC",
      tabStatus: "active",
      tabid: 2,
      tabduration: "32min",
    },
    {
      tablabel: "Assignment",
      tabStatus: "active",
      tabduration: "32min",
      tabid: 3,
    },
    {
      tablabel: "Diagnosis",
      tabStatus: "active",
      //tabStatus: "failed",
      tabduration: "32min",
      tabid: 4,
    },
    {
      tablabel: "Report & Dist",
      tabStatus: "active",
      //tabStatus: "Inactive",
      tabid: 5,
      tabduration: "--",
    },
  ];
  configJson = [
    {
      ActionPerformed: "Accession Case",
      templateid: 21,
      tabid: 2,
      sequenceOrder: 21,
    },
    {
      ActionPerformed: "Completed Accession",
      templateid: 21,
      tabid: 2,
      sequenceOrder: 21,
    },
    {
      ActionPerformed: "Completed Grossing",
      templateid: 23,
      tabid: 2,
      sequenceOrder: 23,
    },
    {
      ActionPerformed: "Completed Processing",
      templateid: 24,
      tabid: 2,
      sequenceOrder: 24,
    },
    {
      ActionPerformed: "Assigned Case to",
      templateid: 32,
      tabid: 3,
      sequenceOrder: 31,
    },
    {
      ActionPerformed: "Modified Diagnosis",
      templateid: 41,
      tabid: 4,
      sequenceOrder: 41,
    },
    {
      ActionPerformed: "Signed Out",
      templateid: 43,
      tabid: 4,
      sequenceOrder: 43,
    },
    {
      ActionPerformed: "Signed Out as Final",
      templateid: 51,
      tabid: 5,
      sequenceOrder: 51,
    },
    {
      ActionPerformed: "Distribution Event Processed",
      templateid: 52,
      tabid: 5,
      sequenceOrder: 52,
    },
  ];
  sequenceListDataBackup: any;


  constructor(private service: CommonService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) {}
  ngOnChanges() {
    if (
      this.caseData.CaseGUID !== null &&
      this.caseData.CaseGUID !== undefined
    ) {
      let obj = {
        // CaseGUID: "412A5121-4E6C-4D5E-9BF1-21C5BBE7EA70",
        CaseGUID: this.caseData.CaseGUID,
        DeploymentKey: this.caseData.deploymentKey.toUpperCase(),
        AccessionNumber:this.caseData.AccessionNumber,
        RequisitionNumber:this.caseData.RequisitionNumber
      };
      this.ngxUiLoaderService.start();
      this.service.caseAuditDetails(obj).subscribe({
        next: (data) => {
          if (data.responsebody !== "" && data.responsebody !== null) {
            this.auditData = JSON.parse(data.responsebody);
            if (this.auditData !== undefined) {
              this.auditData = this.auditData.slice(Math.max(this.auditData.length - 100, 0));;
              obj["username"] = "";
              this.auditData.map((va, index) => {
                (va["date"] = va.AuditDate.split(" ")[0]),
                  (va["time"] = va.AuditDate.split(" ")[1]);
                va["id"] = index;
              });
              let dataAudit = this.groupBy(this.auditData, "date");
              if (dataAudit !== undefined) {
                let Audt = [];
                for (let [key, value] of Object.entries(dataAudit)) {
                  let obj = {
                    LabelDate: key,
                    Auditlist: value,
                  };
                  Audt.push(obj);
                }
                this.auditListData = Audt;
              }
              this.createSequenceData(this.auditListData);
              // console.log(this.auditListData);
            }
          }
        },
        
        error: (error) => {
          this.ngxUiLoaderService.stop();
          console.error();
        },
      });
    }
  }
  ngOnInit() {
    this.selectsequence(this.parentTablistBackup[0]);
  }
  SelectAudit(item) {
    this.auditDetials = item.id;
    (this.Addtion_Details = item.AdditionalDetails),
      (this.Action_Details = item.id);
  }
  selectTabSequence(data: any) {
    this.selectedTab = data;
  }
  selectsequence(value: Object) {
    ;
    if (value !== undefined) {
      this.sequenceList = this.sequenceListBackup.filter(
        (va) => va.tabid === value["tabid"]
      );
      if(this.sequenceListDataBackup.length){
        this.sequenceListData = this.sequenceListDataBackup.filter(
          (va) => va.tabid === value["tabid"]
        );
      }

      this.selectedTab = value;
    }
  }
  groupBy(objectArray: any, property: any) {
    return objectArray.reduce(function (
      acc: { [x: string]: any[] },
      obj: { [x: string]: any }
    ) {
      if (obj !== undefined) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }
    },
    {});
  }

  createSequenceData(value: any) {
    if (value !== undefined) {
      let sequenceList = [];
      let newArray = value[0].Auditlist.slice(Math.max(value[0].Auditlist.length - 100, 0));
      for (let i = 0; i < newArray.length; i++) {
        for (let j = 0; j < this.configJson.length; j++) {
          if (newArray[i].ActionPerformed.includes(this.configJson[j].ActionPerformed)) {
            let objs = { ...this.configJson[j], ...newArray[i] };
            if(sequenceList.length>0){
let exist = sequenceList.find(va =>va.templateid == objs.templateid);
if(exist !== undefined){
  exist = {...exist,...objs}
  // sequenceList.unshift(obs)
}else{
  sequenceList.unshift(objs)
}
            }else{
              sequenceList.unshift(objs)
            }
          }
        }
      };
      this.sequenceListDataBackup = sequenceList;
      console.log(this.sequenceListData)
    if (sequenceList.length) {
       for (let i = 0; i < sequenceList.length; i++) {
       let sequence = this.ParentsequenceList.find((va) =>va.sequenceOrder === sequenceList[i].sequenceOrder       );
          if (sequence !== undefined) {
            if (this.sequenceList.length > 0) {
              let exist = this.sequenceList.filter((va) => va.sequenceOrder === sequence.sequenceOrder
              );
              if (exist.length === 0) {
                this.sequenceList.push(sequence);
              }
            } else {
              this.sequenceList.push(sequence);
            }
          }
        }
        // console.log("sequenceList:"+  JSON.stringify(this.sequenceList))
        this.sequenceListBackup = JSON.parse(JSON.stringify(this.sequenceList));
        if (
          this.parentTablistBackup.length > 0 &&
          this.sequenceList.length > 0
        ) {
          for (let i = 0; i < this.parentTablistBackup.length; i++) {
            let exist = this.sequenceList.filter(
              (va) => va.tabid === this.parentTablistBackup[i].tabid
            );
            if (exist.length > 0) {
              ;
              this.parentTablist.push(this.parentTablistBackup[i]);
            }
          }
        }
        if (this.parentTablist.length > 0) {
          this.selectsequence(this.parentTablist[0])
        }
      }
    }
  }

  getsequenceDataValue(value:any,seqId:number) {
    let data = this.sequenceListDataBackup.find(va => va.templateid === seqId)
    if(data !== undefined && (data.sequenceOrder === value.sequenceOrder)) {
      value = data;
      return true;
    }
  }
}
